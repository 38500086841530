<template>
  <div class="">
    <!-- <sidebar></sidebar>
        <navigation></navigation> -->
    <div class="">
      <div class="d-none">{{ wiz_step + " | " + step + " | " + currentWaiver }}</div>
      <!-- <pre>{{ this.form.familyMembers }}</pre>
      <pre>{{ this.form.removedFamilyMem }}</pre> -->
      <!-- Content Header (Page header) -->
      <div class="content-header" style="">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark" v-if="currentPath !== 'upgrade'">Renew Membership</h1>
              <h1 class="m-0 text-dark" v-else>Upgrade Membership</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item">Renew Membership</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->

        <div class="row rm-p-m">
          <div class="col-md-12">
            <div class="clubrenewal">
              <div class="tabbable">
                <div class="h-steps ">
                  <section>
                    <ul class="header-navigation">
                      <li><span :class="!step7 && wiz_step1 ? 'header-steps current-item' : 'header-steps'">Member Details</span></li>
                      <li><span :class="step7 ? 'header-steps current-item' : 'header-steps'">Donation</span></li>
                      <li :class=" subdomain == 'waterski' ? '' : 'd-none'"><span :class=" wiz_step == 2 ? 'header-steps current-item' : 'header-steps'">Merchandise</span></li>
                      <li><span :class="!step7 && wiz_step3 ? 'header-steps current-item' : 'header-steps'">Verify</span></li>
                      <li><span :class="!step7 && wiz_step4 ? 'header-steps current-item' : 'header-steps'">Legal Agreement</span></li>
                      <li><span :class="!step7 && wiz_step5 ? 'header-steps current-item' : 'header-steps'">Payment</span></li>
                      <li><span :class="!step7 && wiz_step6 ? 'header-steps current-item' : 'header-steps'">Confirmation</span></li>
                    </ul>
                  </section>
                </div>
              </div>
            </div>
            <div>
              <div v-show="wiz_step1" class="">
              <section v-show = "step1">
                <div
                    class="accordion  membership-type"
                    role="tablist"
                >
                  <div class="card-header register-title">
                    <h3 class="mb-3">Membership Types</h3>
                    <p class="font-13 mb-0 mb-pad-10 mt-2 text-justify" v-html="this.getSiteProps('memberRegistration.description')"></p>
                  </div>
                  <!-- HTML title specified via default slot -->
                  <b-tooltip target="button-2" placement="bottom">
                    Choose a membership that’s right for you. Refer to the
                    <a
                        href="http://www.usawaterski.org/graphics/downloads/MembershipOptions.pdf"
                        target="_blank"
                    >Membership Options (PDF)</a
                    >
                    for additional information on our membership type.
                  </b-tooltip>
                  <i class="fa fa-circle-notch fa-spin fa-2x m-4" v-if="propsMemberTypes.length === 0"></i>
                  <template v-for="(memberType, index) in propsMemberTypes">
                  <b-card
                      no-body
                      class="mb-1"
                      :key="'propType'+index" 
                      v-show="memberType.visible != 0 && memberType.id != 4 && familyMemberRenew != memberType.id"

                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle="'accordion-' + memberType.id" variant="info">{{
                          memberType.name
                        }}
                      </b-button>
                    </b-card-header>
        <b-collapse :id="'accordion-' + memberType.id" accordion="my-accordion"
                        role="tabpanel">
                      <b-card-body>
                        <b-card-text>
                          <div v-if="memberType.shortDesc">
                              <p class="font-13 text-justify pl-3 pr-3" v-html="memberType.shortDesc"></p>
                          </div>
                          <div class="">
                            <ul class="mship-type-fld d-flex">
                              <li class="member-type-width pl-3 pt-2 mr-0"
                                  v-for="(membership, idx) in memberType.memberships"
                                  :key="'membership' + membership.id"
                                  :class="{ 'disabled' : membership.disabled == 1 || priceFetching }"
                                  v-show="membership.visible != 0"
                              >
                                <input
                                    @click="
                                      collectMemberFee(
                                        membership,
                                        membership.price,
                                        membership.id,
                                        membership.type,
                                        membership.agree,
                                        membership.age,
                                        memberType.id
                                      )
                                    "
                                    type="radio"
                                    :id="'cb' + membership.type.replace(' ', '_') + idx"
                                    name="form.membership_id"
                                    v-model="form.membership_id"
                                    :value="membership.id"
                                    :disabled="membership.disabled == 1 || priceFetching"
                                />
                                <label
                                    :for="'cb' + membership.type.replace(' ', '_') + idx"
                                >
                                  <span class="chk-top-text pb-3" v-html="membership.type"></span>

                                  <div class="pb-3" v-if="membership.icon">
                                      <img :src="membership.icon" :alt="'icon-'+membership.id" />
                                  </div>

                                  <template v-if="priceFetching"><i class="fa fa-circle-notch fa-spin"></i></template>
                                  <template v-else>
                                    <span class="chk-bot-text pb-2">
                                      <span v-if="membership.price">${{ membership.price }}</span>
                                      <span v-else>--</span>
                                    </span>
                                    <span class="chk-box-text">{{ membership.note }}</span>
                                  </template>
                                </label>

                                <div
                                    class="grossrootcheck"
                                    v-if="form.membership_id === membership.id && form.agree"
                                >
                                  <div class="form-group">
                                    <div class="form-check waiverdatepicker">
                                      <h6 v-html="membership.agree.headings"></h6>
                                      <ul class="grassrootul" v-if="membership.agree.info_list">
                                        <li v-for="(item, k) in membership.agree.info_list" :key="'memList-'+k">
                                          <span style="display: block;" class="font-13 align-items-baseline">
                                            <i class="fas fa-chevron-right"></i><span style="display: contents;" v-html="item"></span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div class="width-values-400">
                                        <input
                                            type="checkbox"
                                            :id="'membership_sign_grossroot' + membership.id"
                                            class="form-check-input"
                                            name="form.membership_sign_grossroot"
                                            value="1"
                                            v-model="form.membership_agree"
                                        />
                                        <label
                                            :for="'membership_sign_grossroot' + membership.id"
                                            class="form-check-label font-13"
                                            v-html="membership.agree.check_label"
                                        >
                                        </label
                                        >
                                      </div>
                                    </div>
                                    <span class="text-sm text-danger">{{
                                    errors.membership_agree
                                    }}</span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <span class="text-sm text-danger">{{ errors.membership }}</span>
                          </div>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  </template>

                  <div class="col-md-12" v-if="this.getSiteProps('general.payment_fee_info')">
                    <p class="mt-3 font-13">
                      <i
                      >All transactions also include a 3% processing/technology fee at
                        checkout.</i
                      >
                    </p>
                  </div>
                </div>
              </section>

                <section v-show = "step2">
                  <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <span v-for="(error,i) in errors" :key="i" class="text-danger">{{ error }}</span>
                  </p>

                 <personalinformation v-bind:membership="membership" :diversities="diversities" :clubData="form.affiliate_club" :formData="form.members" @personal-data="recordPersonalData($event)" @previous-step="previousStep()" :fields="fields.personal"></personalinformation>

                
                </section>
                <section v-show = "step3">
                  <p v-if="errors.length">

                    <span v-for="(error,i) in errors" :key="i" class="text-danger">{{ error }}</span>
                  </p>
                <contactinfo :formData="form" @contact-data="recordContactData($event)"  v-bind:listOfCountries="listOfCountries" v-bind:listOfStates="listOfStates" @previous-step="previousStep()" :fields="fields.contact"></contactinfo>
                </section>
                <section v-show = "step4">
                <generalinfo @general-info-data="recordGeneralInfoData($event)" :disciplines="disciplines" :groups="groups" @previous-step="previousStep()" :formData="form" v-bind:membership="membership" :fields="fields.general" :age="age"></generalinfo>
                </section>
                <section v-show = "step5">
                  <demographicinformation @demographic-data="recordDemographicData($event)"  :formData="form" :diversities="diversities"  @previous-step="previousStep()" :fields="fields.demographic" :categories="categories" :age="age"></demographicinformation>
                </section>
                <section v-show = "step6">
                  <familymembers @family-data="recordFamilyInfo($event)" :diversities="diversities" :members="this.form.members"  :disciplines="disciplines" :currentRegContacts="currentRegContacts" @family-remove-data="removeMembers($event)" @previous-step="previousStep()" @next-step="wizardNextstep()" v-bind:fields="fields.family" :groups="groups" :next_renewal_period="form.next_renewal_period"></familymembers>
                </section>
                <section v-show = "step7">
                  <donation @donation-amount-data="recordDonationAmountData($event)"  @previous-step="previousStep()" v-bind:button="button" v-bind:fields="fields.donation"></donation>
                </section>

              </div>
              <merchandise v-show="wiz_step2" v-bind:regId="regId"
                           v-bind:merchandiseDataMen="this.merchandiseDataMen"
                           v-bind:merchandiseDataWomen="this.merchandiseDataWomen"
                           v-bind:merchandiseDataAccessories="this.merchandiseDataAccessories"
                           @added-to-cart="recordAddedToCart($event)"
                           @previous-step="previousStep()"
                           @placeOrder="placeOrderApi()"
                           @placeOrderCalculation="placeOrderCalculation()"
                           v-bind:buttonMain="button" @cart-products="cartProducts($event)" v-bind:fields="fields.merchandise"></merchandise>
              <div v-show="wiz_step3 && membership.member_type_id != 3" class="row verify-view">
                <div class="col-md-6">
                  <div class="card-body card">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Membership Type</h3>
                      </div>
                      <!--<div class="col-md-3 text-right">
                                             <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>
                                            </div>-->
                      <div class="col-md-12">
                        <span class="Individual" style="text-transform: capitalize;">{{ form.member_type_text | appendMembershipWord }} Membership</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body card">
                    <b-modal ok-only ref="modal"
                             @show="resetModal"
                             ok-title="Update"
                             @hidden="resetModal" @ok="handlePersonalEditOk" id="modal-personal-edit" size="lg" centered
                             title="Personal Information">
                      <div class="popupbody">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="form-label">First Name <sup class="text-danger">*</sup></label>
                              <input type="hidden" class="form-control" name="verifyPersonal.relation"
                                     v-model="verifyData.members.relation">
                              <input type="text" class="form-control" maxlength="45" name="verifyPersonal.first_name"
                                     v-model="verifyData.members.first_name">
                              <span class="text-sm text-danger">{{ errors.first_name }}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="form-label">Middle Initial</label>
                              <input type="text" class="form-control" maxlength="3" name="verifyPersonal.middle_name"
                                     v-model="verifyData.members.middle_name">
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="form-label">Last Name <sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" maxlength="45" name="verifyPersonal.last_name"
                                     v-model="verifyData.members.last_name">
                              <span class="text-sm text-danger">{{ errors.last_name }}</span>
                            </div>

                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Birthday <sup class="text-danger">*</sup></label>
                              <div class="input-group birthdate">
                                <div class="birthdaypicker">
                                  <!-- <i class="fas fa-calendar-alt"></i> -->
                                  <date-pick
                                      v-model="verifyData.members.dob"
                                      :pickTime="false"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      name="verifyData.members.dob"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :isDateDisabled="isFutureDate"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                  <!--<datetime format="MM/DD/YYYY" v-model="verifyData.members.dob"></datetime>-->
                                </div>
                                <span class="text-sm text-danger">{{ errors.dob }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="citizenship" class="form-label">Citizenship <sup
                                  class="text-danger">*</sup></label>
                              <select class="form-control" id="citizenship" name="verifyPersonal.citizenship"
                                      v-model="verifyData.members.citizenship">
                                <option value="USA">USA</option>
                                <option value="Canada">Canada</option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.citizenship }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Gender <sup class="text-danger">*</sup></label><br>
                              <input type="radio" id="verify_gender_male" name="verifyPersonal.gender"
                                     v-model="verifyData.members.gender" value="Male">
                              <label class="gender-label-span" for="verify_gender_male">Male</label><br>
                              <input type="radio" id="verify_gender_female" name="verifyPersonal.gender"
                                     v-model="verifyData.members.gender" value="Female">
                              <label class="gender-label-span" for="verify_gender_female">Female</label><br>
                              <input type="radio" id="other" name="verifyPersonal.gender"
                                     v-model="verifyData.members.gender" value="Other">
                              <label class="gender-label-span" for="other">Other</label><br>
                              <input type="radio" id="verify_gender_prefernottosay" name="verifyPersonal.gender"
                                     v-model="verifyData.members.gender" value="Prefer not to say">
                              <label class="gender-label-span" for="verify_gender_prefernottosay">Prefer not to
                                say</label><br>
                              <span class="text-sm text-danger">{{ errors.gender }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-modal>
                    <div class="row">
                      <div class="col-md-9">
                        <h3 v-if="wiz_step3 && fields.personal && fields.personal.sub_title">{{ fields.personal.sub_title }}</h3>
                        <h3 v-else>{{ wiz_step3 && fields.personal && fields.personal.tab_title ? fields.personal.tab_title : 'Personal Information' }}</h3>
                      </div>
                      <div class="col-md-3 text-right">
                        <div class="modal-btn">
                          <!-- <b-button v-b-modal.modal-personal-edit><img
                              v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit
                          </b-button> -->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-sm-6" v-if="wiz_step3 && fields.personal && fields.personal.title">
                            <label>Title</label>
                            <span>{{ form.members[0].title || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>First Name</label>
                        <span>{{ form.members[0].first_name }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>Middle Initial</label>
                        <span>{{ form.members[0].middle_name || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>Last Name</label>
                        <span>{{ form.members[0].last_name }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                            <label>Suffix</label>
                            <span>{{ form.members[0].suffix || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>{{ wiz_step3 && fields.personal.birthday && fields.personal.birthday.label ? fields.personal.birthday.label : 'Birthday' }}</label>
                        <span>{{ form.members[0].dob }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>Gender</label>
                        <span>{{ form.members[0].gender }}</span>
                      </div>
                      <template v-if="wiz_step3 && fields.general && fields.general.sport_discipline && fields.general.sport_discipline.home_federation">
                        <div v-if="form.home_federation" class="col-md-6 col-sm-6">
                              <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="cityzenship_icon">Federation</label>
                              <span>{{ form.home_federation }}</span>
                        </div>
                        <div v-if="form.home_federation != 'USA' && form.skier_license" class="col-md-6">
                              <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="relationship">Foreign Federation ID</label>
                              <span>{{ form.skier_license }}</span>
                        </div>
                      </template>
                      <div v-if="form.members[0].veteran_status" class="col-md-6 col-sm-6">
                            <label>{{ wiz_step3 && fields.personal && fields.personal.veteran_status && fields.personal.veteran_status.label ? fields.personal.veteran_status.label : 'Veteran Status' }}</label>
                            <span>{{ form.members[0].veteran_status }}</span>
                      </div>
                      <div v-if="form.affiliate && form.members[0].club_name &&!(subdomain=='shooting'&&membership.member_type_id == 7)" class="col-md-6">
                            <label>{{ subdomain != 'shooting' ? 'Affiliated Clubs/Teams' : 'Club / Team' }}</label>
                            <span>{{ form.members[0].club_name }}</span>
                      </div>
                      <div class="col-md-6" v-if="wiz_step3 && fields.general && fields.general.sport_discipline && fields.general.sport_discipline.first_choice&&!(subdomain=='shooting'&&membership.member_type_id == 7)">
                          <label>{{ wiz_step3 && fields.general.sport_discipline.first_choice && fields.general.sport_discipline.first_choice.label ? fields.general.sport_discipline.first_choice.label : 'Disciplines of Interest' }}</label>
                          <ul class="pl-3" v-if="form.disciplines_names && form.disciplines_names.length">
                              <li v-for="(item, i) in form.disciplines_names" :key="'discipline-show'+i">
                                  {{ item.name }}
                              </li>
                          </ul>
                          <span v-else>{{ form.members[0].first_choice_txt }}</span>
                      </div>
                      <div class="col-md-6" v-if="wiz_step3 && fields.general && fields.general.sport_discipline && fields.general.sport_discipline.second_choice">
                          <template v-if="form.second_choice_txt">
                              <label>{{ wiz_step3 && fields.general.sport_discipline.second_choice && fields.general.sport_discipline.second_choice.label ? fields.general.sport_discipline.second_choice.label : 'Disciplines of Interest' }}</label>
                              <span>{{ form.second_choice_txt || "N/A" }}</span>
                          </template>
                      </div>
                    </div>
                    <div class="row" v-show="form.members[0].club == '-' ">
                        <div class="col-md-12 mt-3">
                            <h3>New Club Information</h3>
                        </div>
                        <div v-if="form.members[0].new_club_name" class="col-md-6">
                              <label>Club Name</label>
                              <span>{{ form.members[0].new_club_name }}</span>
                        </div>
                        <div v-if="form.members[0].new_club_email" class="col-md-6">
                              <label>Club E-Mail</label>
                              <span>{{ form.members[0].new_club_email }}</span>
                        </div>
                        <div v-if="form.members[0].new_club_phone" class="col-md-6">
                              <label>Club Phone</label>
                              <span>{{ form.members[0].new_club_phone }}</span>
                        </div>
                    </div>
                  </div>
                  <div class="card-body card">

                    <div class="row">
                      <div class="col-md-9">
                        <h3>{{ wiz_step3 && fields.demographic.tab_title ? fields.demographic.tab_title : 'Demographic Information'}}</h3>
                      </div>
                      <div class="col-md-3 text-right">
                        <div class="modal-btn">
                          <!-- <b-button v-b-modal.modal-demographic-edit><img
                              v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit
                          </b-button> -->
                          <b-modal ref="modal" ok-only
                                   @show="resetModal"
                                   ok-title="Update"
                                   @hidden="resetModal" @ok="handlePersonalDemographicEditOk"
                                   id="modal-demographic-edit" size="lg" centered title="Demographic Information">
                            <div class="popupbody">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group radiotext ">
                                    <label for="race" class="form-label"><strong>Race</strong><sup
                                        class="text-danger">*</sup></label> <br>
                                    <div v-for="(diversity,i) in diversities" :key="i">
                                      <input type="radio" :id="'diversity'+diversity.name"
                                             name="verifyPersonal.diversity_id" :value="diversity.id"
                                             v-model="verifyData.diversity_id">
                                      <label :for="'diversity'+diversity.name">{{ diversity.name }}</label> <br>
                                    </div>
                                    <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group radiotext ">
                                    <label for="ethnicitystatus" class="form-label"><strong>Ethnicity</strong><sup
                                        class="text-danger">*</sup></label>
                                    <div class="form-group">

                                      <label for="hispanic-or-latino"><input type="radio" id="hispanic-or-latino"
                                                                             name="ethnicity" value="Hispanic or Latino"
                                                                             v-model="verifyData.ethnicity"> Hispanic or
                                        Latino</label><br>

                                      <label for="non-hispanic-or-latino"> <input type="radio"
                                                                                  id="non-hispanic-or-latino"
                                                                                  name="ethnicity"
                                                                                  value="Non-Hispanic or Latino"
                                                                                  v-model="verifyData.ethnicity">
                                        Non-Hispanic or Latino</label><br>

                                      <label for="ethnicity-Prefer-not-to-say"> <input type="radio"
                                                                                       id="ethnicity-Prefer-not-to-say"
                                                                                       name="ethnicity"
                                                                                       value="Prefer not to say"
                                                                                       v-model="verifyData.ethnicity">
                                        Prefer not to say</label><br>
                                      <span class="text-sm text-danger">{{ errors.ethnicity }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group radiotext ">
                                    <label for="disabilitystatus" class="form-label"><strong>Disability Status</strong>
                                      <sup class="text-danger">*</sup></label>
                                    <div class="form-group">

                                      <label for="normal"> <input type="radio" id="normal"
                                                                  name="verifyPersonal.disability"
                                                                  value="Persons without Disability"
                                                                  v-model="verifyData.disability"> Persons without
                                        Disability</label><br>

                                      <label for="Persons-with-Disability"><input type="radio"
                                                                                  id="Persons-with-Disability"
                                                                                  name="verifyPersonal.disability"
                                                                                  value=" Persons with Disability"
                                                                                  v-model="verifyData.disability">
                                        Persons with Disability</label><br>

                                      <label for="disability-Prefer-not-to-say"><input type="radio"
                                                                                       id="disability-Prefer-not-to-say"
                                                                                       name="verifyPersonal.disability"
                                                                                       value="Prefer not to say"
                                                                                       v-model="verifyData.disability">
                                        Prefer not to say</label><br>
                                      <span class="text-sm text-danger">{{ errors.disability }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group radiotext ">
                                    <label for="veteranstatus" class="form-label"><strong>Veteran Status</strong> <sup
                                        class="text-danger">*</sup></label>
                                    <div class="form-group">
                                      <div>

                                        <label for="veteran"><input type="radio" id="veteran"
                                                                    name="verifyPersonal.veteran_status" value="Veteran"
                                                                    v-model="verifyData.veteran_status"> Veteran</label><br>
                                      </div>
                                      <div>

                                        <label for="non-Veteran"> <input type="radio" id="non-Veteran"
                                                                         name="verifyPersonal.veteran_status"
                                                                         value="Non-Veteran"
                                                                         v-model="verifyData.veteran_status">
                                          Non-Veteran</label><br>
                                      </div>
                                      <div>

                                        <label for="Active-or-Reserve"> <input type="radio" id="Active-or-Reserve"
                                                                               name="verifyPersonal.veteran_status"
                                                                               value="Active or Reserve"
                                                                               v-model="verifyData.veteran_status">
                                          Active or Reserve</label><br>

                                      </div>
                                      <div>

                                        <label for="vet-prefer-not-to-say"> <input type="radio"
                                                                                   id="vet-prefer-not-to-say"
                                                                                   name="verifyPersonal.veteran_status"
                                                                                   value="Prefer not to say"
                                                                                   v-model="verifyData.veteran_status">
                                          Prefer not to say</label><br>
                                      </div>

                                      <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </b-modal>
                        </div>
                        <!-- <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>-->
                      </div>
                    </div>
                    <div class="row" v-if="fields.demographic">
                      <div class="col-md-6" v-if="fields.demographic.race">
                        <label>{{ fields.demographic.race.label || 'Race'}}</label>
                        <!-- <span 
                        class="mb-0"
                        v-for="(diversity,i) in diversities" :key="i">
                            <p v-if="diversity.id == form.members[0].diversity_id">
                            {{diversity.name}}
                            </p>
                        </span> -->
                        <span class="mb-0">{{ form.diversity_txt }}</span>
                      </div>
                      <div class="col-md-6" v-if="fields.demographic.ethnicity">
                        <label>{{ fields.demographic.ethnicity.label || 'Ethnicity'}}</label>
                        <span>{{ form.ethnicity }}</span>
                      </div>
                      <div class="col-md-6" v-if="fields.demographic.disability_status">
                        <label>{{ fields.demographic.disability_status.label || 'Disability'}}</label>
                        <span>{{ form.disability }}</span>
                      </div>
                      <div class="col-md-6" v-if="fields.demographic.veteran_status">
                        <label>{{ fields.demographic.veteran_status.label || 'Veteran Status'}}</label>
                        <span>{{ form.veteran_status }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.primary_area_interest">
                          <label>Primary Role of Interest</label>
                          <span>{{ form.members[0].primary_area_interest || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.category">
                            <label>Category</label> 
                            <span>{{ form.members[0].category_name || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.hear_about">
                            <label>How did you hear about {{ getSiteProps('general.site_name') }} ?</label>
                            <span>{{ form.members[0].hear_about || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.issf">
                            <label>ISSF License Certification Number</label>
                            <span>{{ form.members[0].issf || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.cmp">
                            <label>CMP Membership #</label>
                            <span>{{ form.members[0].cmp_no || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.nra">
                            <label>NRA Membership #</label>
                            <span>{{ form.members[0].nra_no || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.sasp">
                            <label>SASP Membership #</label>
                            <span>{{ form.members[0].sasp_no || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.sctp">
                            <label>SCTP Membership #</label>
                            <span>{{ form.members[0].sctp_no || "N/A" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body card">

                    <div class="row">
                      <div class="col-md-9">
                        <h3>{{ wiz_step3 && fields.contact.tab_title ? fields.contact.tab_title : 'Contact Information'}}</h3>
                      </div>
                      <div class="col-md-3 text-right">
                        <div class="modal-btn">
                          <!-- <b-button v-b-modal.modal-contact-edit><img
                              v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit
                          </b-button> -->
                          <b-modal ok-only ref="modal"
                                   @show="resetModal"
                                   ok-title="Update"
                                   @hidden="resetModal" @ok="handlePersonalContactEditOk" id="modal-contact-edit"
                                   size="lg" centered title="Contact Information">
                            <div class="popupbody">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="email" class="form-label">Email <sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="45" id="email"
                                           v-model="verifyData.email" name="verifyData.email">
                                    <span class="text-sm text-danger">{{ errors.email }}</span>
                                  </div>
                                  <div class="form-group">
                                    <label for="retype_email" class="form-label">Retype Email <sup
                                        class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="45" id="retype_email"
                                           v-model="verifyData.retype_email" name="verifyData.retype_email">
                                    <span class="text-sm text-danger">{{ errors.retype_email }}</span>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="verifyData_firstname" class="form-label">Mailing Address <sup
                                        class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="100" id="verifyData_address1"
                                           v-model="verifyData.address1" name="verifyData.address1">
                                    <span class="text-sm text-danger">{{ errors.address1 }}</span>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="verifyData_firstname" class="form-label">Address 2</label>
                                    <input type="text" class="form-control" maxlength="45" id="verifyData_address2"
                                           v-model="verifyData.address2" name="verifyData.address2">
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="Country" class="form-label">Country <sup
                                        class="text-danger">*</sup></label>
                                    <select class="form-control" id="verifyData_country" v-model="verifyData.country"
                                            name="verifyData.country">
                                      <option v-bind:value="listOfCountry.country"
                                              v-for="listOfCountry in listOfCountries" :key="listOfCountry.country"
                                              :selected="verifyData.country == listOfCountry.country">
                                        {{ listOfCountry.country }}
                                      </option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.country }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 " v-if="verifyData.country == 'USA' ">
                                  <div class="form-group">
                                    <label for="state_code" class="form-label">State <sup
                                        class="text-danger">*</sup></label>
                                    <select class="form-control" id="verifyData_state_code"
                                            v-model="verifyData.state_code" name="verifyData.state_code">
                                      <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                              :value="listOfState.state_code"
                                              :selected="verifyData.state_code == listOfState.state_code">
                                        {{ listOfState.state }}
                                      </option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.state_code }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6" v-else>
                                  <div class="form-group">
                                    <label for="state_code" class="form-label">Other State <sup
                                        class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="45" v-model="verifyData.state"
                                           name="verifyData.state">
                                    <span class="text-sm text-danger">{{ errors.state }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 ">
                                  <div class="form-group">
                                    <label for="city" class="form-label">City <sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="45" id="verifyData_city"
                                           v-model="verifyData.city" name="verifyData.city">
                                    <span class="text-sm text-danger">{{ errors.city }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 ">
                                  <div class="form-group">
                                    <label for="zip" class="form-label">Postal Code<sup
                                        class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="15" id="verifyData_zip"
                                           v-model="verifyData.zip" name="verifyData.zip">
                                    <span class="text-sm text-danger">{{ errors.zip }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 ">
                                  <div class="form-group ">
                                    <div class="phone-nu-wrapper">
                                      <label for="verifyData_phone_1" class="form-label">Home phone with Country Code
                                        <sup class="text-danger">*</sup></label>
                                      <vue-tel-input id="verifyData_phone_1" v-model="verifyData.phone_1" ref="phone1"
                                                     maxlength="20" v-bind="homeProps"
                                                     :validCharactersOnly="true"></vue-tel-input>
                                      <!-- <input type="text" class="form-control" id="country_code_h" name="country_code_h" placeholder="+1" v-mask="'(###)'">
                                                                             <input type="tel" class="form-control" id="phone_1" v-model="form.phone_1"  name="form.phone_1" v-mask="'###-####'">-->
                                      <span class="text-sm text-danger">{{ errors.phone_1 }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <div class="phone-nu-wrapper">
                                      <label for="verifyData_phone_2" class="form-label">Mobile with Country Code  <sup
                                          class="text-danger">*</sup></label>
                                      <vue-tel-input id="verifyData_phone_2" v-model="verifyData.phone_2" ref="phone2"
                                                     maxlength="20" v-bind="mobileProps"
                                                     :validCharactersOnly="true"></vue-tel-input>
                                      <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1" v-mask="'(###)'">
                                                                            <input type="tel" class="form-control" id="phone_2" v-model="form.phone_2"  name="form.phone_2" v-mask="'###-####'">-->
                                      <span class="text-sm text-danger">{{ errors.phone_2 }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-modal>
                        </div>
                        <!-- <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>-->
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                          <label>{{ wiz_step3 && fields.contact.homephone.label ? fields.contact.homephone.label : 'Phone' }}</label>
                          <span>{{ form.phone_1 || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>{{ wiz_step3 && fields.contact.mobilephone.label ? fields.contact.mobilephone.label : 'Mobile' }}</label>
                          <span>{{ form.phone_2 || 'N/A' }}</span>
                      </div>
                      <div class="col-md-12">
                          <label>Email</label>
                          <span>{{ form.email || 'N/A'}}</span>
                      </div>
                      <div class="col-md-12" v-show="form.address_type">
                          <label>Address Type</label>
                          <span>{{ form.address_type || 'N/A' }} Address</span>
                      </div>
                      <div class="col-md-6">
                          <label>{{ wiz_step3 && fields.contact.address1.label ? fields.contact.address1.label : 'Mailing Address' }}</label>
                          <span>{{ form.address1 || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>{{ wiz_step3 && fields.contact.address2.label ? fields.contact.address2.label : 'Address 2' }}</label>
                          <span>{{ form.address2 || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>City</label>
                          <span>{{ form.city || 'N/A'}}</span>
                      </div>
                      <div class="col-md-6">
                          <label>State</label>
                          <span v-if="form.country == 'USA'">{{
                                  form.state_code
                              }}</span>
                          <span v-else>{{ form.state }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>Postal Code</label>
                          <span>{{ form.zip }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>Country</label>
                          <span>{{ form.country || 'N/A' }}</span>
                      </div>
                      <template v-if="form.address_type == 'Work'">
                          <div class="col-md-6" v-show="form.company_name">
                              <label>Company Name</label>
                              <span>{{ form.company_name || 'N/A' }}</span>
                          </div>
                          <div class="col-md-6" v-show="form.company_phone">
                              <label>Company Phone with Extension</label>
                              <span>{{ form.company_phone  || 'N/A'}} | Ext: {{ form.company_phone_extension || 'N/A'}}</span>
                          </div>
                      </template>
                    </div>
                  </div>
                  <div v-if="form.members.length > 0">
                    <template v-for="(family_member, findex) in form.familyMembers">
                      <div class="card-body card" :key="findex">
                          <div class="row">
                              <div class="col-md-9">
                                  <h3>{{ family_member.relation == 1 ? 'Spouse' : 'Child' }} Information</h3>
                              </div>
                              <div class="col-md-3 text-right">

                                <!-- <div class="modal-btn">
                                  <b-button @click.prevent="editFamily(findex)"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                                </div> -->

                              </div>
                          </div>
                          <div class="row">
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.firstName.label ? fields.family.popup.firstName.label : 'First Name' }}</label>
                                  <span>{{ family_member.first_name }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.middleInitial.label ? fields.family.popup.middleInitial.label : 'Middle Initial' }}</label>
                                  <span>{{ family_member.middle_name || 'N/A' }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.LastName.label ? fields.family.popup.LastName.label : 'Last Name' }}</label>
                                  <span>{{ family_member.last_name }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.relation.label ? fields.family.popup.relation.label : 'Relationship' }}</label>
                                  <span>{{ family_member.relation == 1 ? 'Spouse' : 'Child' }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.birthDay.label ? fields.family.popup.birthDay.label : 'Birthday' }}</label>
                                  <span>{{ family_member.dob }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.gender.label ? fields.family.popup.gender.label : 'Gender' }}</label>
                                  <span>{{ family_member.gender }}</span>
                              </div>
                              <div class="col-sm-12">
                                  <label>{{ wiz_step3 && fields.family.popup.email.label ? fields.family.popup.email.label : 'Email' }}</label>
                                  <span>{{ family_member.email }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.homephone.label ? fields.family.popup.homephone.label : 'Home Phone' }}</label>
                                  <span>{{ family_member.phone_1 }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.mobile.label ? fields.family.popup.mobile.label : 'Mobile Phone' }}</label>
                                  <span>{{ family_member.phone_2 || 'N/A' }}</span>
                              </div>
                              <div class="col-md-6">
                                  <label>{{ wiz_step3 && fields.family.popup.race.label ? fields.family.popup.race.label : 'Race' }}</label>
                                  <span>{{ family_member.diversity_txt || 'N/A' }}</span>
                              </div>
                              <div v-if="wiz_step3 && fields.family.popup.clubandteam" class="col-md-6">
                                <label>{{ subdomain != 'shooting' ? wiz_step3 && fields.family.popup.clubandteam.label : 'Club / Team' }}</label>
                                <span>{{ family_member.affiliated_clubs_names }}</span>
                              </div>
                              <div v-if="wiz_step3 && fields.family.popup.home_federation" class="col-md-6">
                                <div class="form-group">
                                  <label class="form-label">{{ fields.family.popup.home_federation.label? fields.family.popup.home_federation.label : 'Federation' }}</label>
                                  <span>{{ family_member.home_federation || "N/A" }}</span>
                                </div>
                              </div>
                              <div v-if="wiz_step3 && fields.family.popup.skier_license" class="col-md-6">
                                <div class="form-group">
                                  <label class="form-label">{{ fields.family.popup.skier_license.label ? fields.family.popup.skier_license.label : 'Foreign Federation ID' }}</label>
                                  <span>{{ family_member.skier_license || "N/A" }}</span>
                                </div>
                              </div>
                              <div class="col-md-6" v-if="family_member.first_choice_txt">
                                <label>{{ wiz_step3 && fields.family.popup.sport_discipline.first_choice.label ? fields.family.popup.sport_discipline.first_choice.label : 'First Choice'}}</label>
                                <span>{{ family_member.first_choice_txt }}</span>
                              </div>
                              <div v-if="wiz_step3 && fields.family.popup.sport_discipline.second_choice" class="col-md-6">
                                <label>{{ wiz_step3 && fields.family.popup.sport_discipline.second_choice.label ? fields.family.popup.sport_discipline.second_choice.label : 'Second Choice'}}</label>
                                <span>{{ family_member.second_choice_txt }}</span>
                              </div>
                              <div class="col-md-6" v-if="family_member.displines_names">
                                  <label>{{ wiz_step3 && fields.general.sport_discipline && fields.general.sport_discipline.first_choice && fields.general.sport_discipline.first_choice.label ? fields.general.sport_discipline.first_choice.label : 'Disciplines' }}</label>
                                  <ul v-if="family_member.displines_names" class="pl-3">
                                      <li v-for="(item, k) in family_member.displines_names" :key="'v-disp'+k">
                                          {{ item }}
                                      </li>
                                  </ul>
                              </div>
                            <div class="col-md-6" v-if="wiz_step3 && fields.family.popup.ethnicity">
                              <label>{{ wiz_step3 && fields.family.popup.ethnicity.label ? fields.family.popup.ethnicity.label : 'Ethnicity'}}</label>
                              <span>{{ family_member.ethnicity }}</span>
                            </div>
                            <div class="col-md-6" v-if="wiz_step3 && fields.family.popup.disability_status">
                              <label>{{ wiz_step3 && fields.family.popup.disability_status.label ? fields.family.popup.disability_status.label : 'Disability'}}</label>
                              <span>{{ family_member.disability }}</span>
                            </div>
                            <div class="col-md-6" v-if="wiz_step3 && fields.family.popup.veteran_status">
                              <label>{{ wiz_step3 && fields.family.popup.veteran_status.label ? fields.family.popup.veteran_status.label : 'Veteran Status'}}</label>
                              <span>{{ family_member.veteran_status }}</span>
                            </div>
                          </div>
                      </div>
                    </template>
                  </div>
                </div>


              </div>
              <div v-show="wiz_step3 && membership.member_type_id == 3" class="row verify-view">
                <div class="col-md-6">
                  <div class="card-body card">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Membership Type</h3>
                      </div>
                      <!--<div class="col-md-3 text-right">
                                             <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>
                                            </div>-->
                      <div class="col-md-12">
                        <span class="Individual" style="text-transform: capitalize;">{{ form.member_type_text | appendMembershipWord }} Membership</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body card">
                    <b-modal ok-only ref="modal"
                             @show="resetModal"
                             ok-title="Update"
                             @hidden="resetModal" @ok="handlePersonalEditOk" id="modal-personal-edit" size="lg" centered
                             title="Personal Information">
                      <div class="popupbody">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="form-label">First Name <sup class="text-danger">*</sup></label>
                              <input type="hidden" class="form-control" name="verifyPersonal.relation"
                                     v-model="verifyData.members.relation">
                              <input type="text" class="form-control" maxlength="45" name="verifyPersonal.first_name"
                                     v-model="verifyData.members.first_name">
                              <span class="text-sm text-danger">{{ errors.first_name }}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="form-label">Middle Initial</label>
                              <input type="text" class="form-control" maxlength="3" name="verifyPersonal.middle_name"
                                     v-model="verifyData.members.middle_name">
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="form-label">Last Name <sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" maxlength="45" name="verifyPersonal.last_name"
                                     v-model="verifyData.members.last_name">
                              <span class="text-sm text-danger">{{ errors.last_name }}</span>
                            </div>

                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Birthday <sup class="text-danger">*</sup></label>
                              <div class="input-group birthdate">
                                <div class="birthdaypicker">
                                  <!-- <i class="fas fa-calendar-alt"></i> -->
                                  <date-pick
                                      v-model="verifyData.members.dob"
                                      :pickTime="false"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      name="verifyData.members.dob"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :isDateDisabled="isFutureDate"
                                  ></date-pick>
                                  <!--<datetime format="MM/DD/YYYY" v-model="verifyData.members.dob"></datetime>-->
                                </div>
                                <span class="text-sm text-danger">{{ errors.dob }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="citizenship" class="form-label">Citizenship <sup
                                  class="text-danger">*</sup></label>
                              <select class="form-control" id="citizenship" name="verifyPersonal.citizenship"
                                      v-model="verifyData.members.citizenship">
                                <option value="USA">USA</option>
                                <option value="Canada">Canada</option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.citizenship }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Gender <sup class="text-danger">*</sup></label><br>
                              <input type="radio" id="verify_gender_male" name="verifyPersonal.gender"
                                     v-model="verifyData.members.gender" value="Male">
                              <label class="gender-label-span" for="verify_gender_male">Male</label><br>
                              <input type="radio" id="verify_gender_female" name="verifyPersonal.gender"
                                     v-model="verifyData.members.gender" value="Female">
                              <label class="gender-label-span" for="verify_gender_female">Female</label><br>
                              <input type="radio" id="other" name="verifyPersonal.gender"
                                     v-model="verifyData.members.gender" value="Other">
                              <label class="gender-label-span" for="other">Other</label><br>
                              <input type="radio" id="verify_gender_prefernottosay" name="verifyPersonal.gender"
                                     v-model="verifyData.members.gender" value="Prefer not to say">
                              <label class="gender-label-span" for="verify_gender_prefernottosay">Prefer not to
                                say</label><br>
                              <span class="text-sm text-danger">{{ errors.gender }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-modal>
                    <div class="row">
                      <div class="col-md-9">
                        <h3 v-if="wiz_step3 && fields.personal && fields.personal.sub_title">{{ fields.personal.sub_title }}</h3>
                        <h3 v-else>{{ wiz_step3 && fields.personal && fields.personal.tab_title ? fields.personal.tab_title : 'Personal Information' }}</h3>
                      </div>
                      <div class="col-md-3 text-right">
                        <div class="modal-btn">
                          <!-- <b-button v-b-modal.modal-personal-edit><img
                              v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit
                          </b-button> -->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-sm-6" v-if="wiz_step3 && fields.personal && fields.personal.title">
                            <label>Title</label>
                            <span>{{ form.members[0].title }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>First Name</label>
                        <span>{{ form.members[0].first_name }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>Middle Initial</label>
                        <span>{{ form.members[0].middle_name || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>Last Name</label>
                        <span>{{ form.members[0].last_name }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                            <label>Suffix</label>
                            <span>{{ form.members[0].suffix || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>{{ wiz_step3 && fields.personal.birthday && fields.personal.birthday.label ? fields.personal.birthday.label : 'Birthday' }}</label>
                        <span>{{ form.members[0].dob }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>Gender</label>
                        <span>{{ form.members[0].gender }}</span>
                      </div>
                      <template v-if="wiz_step3 && fields.general && fields.general.sport_discipline && fields.general.sport_discipline.home_federation">
                        <div v-if="form.home_federation" class="col-md-6 col-sm-6">
                              <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="cityzenship_icon">Federation</label>
                              <span>{{ form.home_federation }}</span>
                        </div>
                        <div v-if="form.home_federation != 'USA' && form.skier_license" class="col-md-6">
                              <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="relationship">Foreign Federation ID</label>
                              <span>{{ form.skier_license }}</span>
                        </div>
                      </template>
                      <div v-if="form.members[0].veteran_status" class="col-md-6 col-sm-6">
                            <label>{{ wiz_step3 && fields.personal && fields.personal.veteran_status && fields.personal.veteran_status.label ? fields.personal.veteran_status.label : 'Veteran Status' }}</label>
                            <span>{{ form.members[0].veteran_status }}</span>
                      </div>
                      <div v-if="form.affiliate && form.members[0].club_name" class="col-md-6">
                            <label>{{ subdomain != 'shooting' ? 'Affiliated Clubs/Teams' : 'Club / Team' }}</label>
                            <span>{{ form.members[0].club_name }}</span>
                      </div>
                      <div class="col-md-6" v-if="wiz_step3 && fields.general && fields.general.sport_discipline && fields.general.sport_discipline.first_choice">
                          <label>{{ wiz_step3 && fields.general.sport_discipline.first_choice && fields.general.sport_discipline.first_choice.label ? fields.general.sport_discipline.first_choice.label : 'Disciplines of Interest' }}</label>
                          <ul class="pl-3" v-if="form.disciplines_names && form.disciplines_names.length">
                              <li v-for="(item, i) in form.disciplines_names" :key="'discipline-show'+i">
                                  {{ item.name }}
                              </li>
                          </ul>
                          <span v-else>{{ form.members[0].first_choice_txt }}</span>
                      </div>
                      <div class="col-md-6" v-if="wiz_step3 && fields.general && fields.general.sport_discipline && fields.general.sport_discipline.second_choice">
                          <template v-if="form.second_choice_txt">
                              <label>{{ wiz_step3 && fields.general.sport_discipline.second_choice && fields.general.sport_discipline.second_choice.label ? fields.general.sport_discipline.second_choice.label : 'Disciplines of Interest' }}</label>
                              <span>{{ form.second_choice_txt || "N/A" }}</span>
                          </template>
                      </div>
                    </div>
                    <div class="row" v-show="form.members[0].club == '-' ">
                        <div class="col-md-12 mt-3">
                            <h3>New Club Information</h3>
                        </div>
                        <div v-if="form.members[0].new_club_name" class="col-md-6">
                              <label>Club Name</label>
                              <span>{{ form.members[0].new_club_name }}</span>
                        </div>
                        <div v-if="form.members[0].new_club_email" class="col-md-6">
                              <label>Club E-Mail</label>
                              <span>{{ form.members[0].new_club_email }}</span>
                        </div>
                        <div v-if="form.members[0].new_club_phone" class="col-md-6">
                              <label>Club Phone</label>
                              <span>{{ form.members[0].new_club_phone }}</span>
                        </div>
                    </div>

                    <div class="row">
                      <div class="col-md-9">
                        <h3>{{ wiz_step3 && fields.demographic.tab_title ? fields.demographic.tab_title : 'Demographic Information'}}</h3>
                      </div>
                      <div class="col-md-3 text-right">
                        <div class="modal-btn">
                          <!-- <b-button v-b-modal.modal-demographic-edit><img
                              v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit
                          </b-button> -->
                          <b-modal ref="modal" ok-only
                                   @show="resetModal"
                                   ok-title="Update"
                                   @hidden="resetModal" @ok="handlePersonalDemographicEditOk"
                                   id="modal-demographic-edit" size="lg" centered title="Demographic Information">
                            <div class="popupbody">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group radiotext ">
                                    <label for="race" class="form-label"><strong>Race</strong><sup
                                        class="text-danger">*</sup></label> <br>
                                    <div v-for="(diversity,i) in diversities" :key="i">
                                      <input type="radio" :id="'diversity'+diversity.name"
                                             name="verifyPersonal.diversity_id" :value="diversity.id"
                                             v-model="verifyData.diversity_id">
                                      <label :for="'diversity'+diversity.name">{{ diversity.name }}</label> <br>
                                    </div>
                                    <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group radiotext ">
                                    <label for="ethnicitystatus" class="form-label"><strong>Ethnicity</strong><sup
                                        class="text-danger">*</sup></label>
                                    <div class="form-group">

                                      <label for="hispanic-or-latino"><input type="radio" id="hispanic-or-latino"
                                                                             name="ethnicity" value="Hispanic or Latino"
                                                                             v-model="verifyData.ethnicity"> Hispanic or
                                        Latino</label><br>

                                      <label for="non-hispanic-or-latino"> <input type="radio"
                                                                                  id="non-hispanic-or-latino"
                                                                                  name="ethnicity"
                                                                                  value="Non-Hispanic or Latino"
                                                                                  v-model="verifyData.ethnicity">
                                        Non-Hispanic or Latino</label><br>

                                      <label for="ethnicity-Prefer-not-to-say"> <input type="radio"
                                                                                       id="ethnicity-Prefer-not-to-say"
                                                                                       name="ethnicity"
                                                                                       value="Prefer not to say"
                                                                                       v-model="verifyData.ethnicity">
                                        Prefer not to say</label><br>
                                      <span class="text-sm text-danger">{{ errors.ethnicity }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group radiotext ">
                                    <label for="disabilitystatus" class="form-label"><strong>Disability Status</strong>
                                      <sup class="text-danger">*</sup></label>
                                    <div class="form-group">

                                      <label for="normal"> <input type="radio" id="normal"
                                                                  name="verifyPersonal.disability"
                                                                  value="Persons without Disability"
                                                                  v-model="verifyData.disability"> Persons without
                                        Disability</label><br>

                                      <label for="Persons-with-Disability"><input type="radio"
                                                                                  id="Persons-with-Disability"
                                                                                  name="verifyPersonal.disability"
                                                                                  value=" Persons with Disability"
                                                                                  v-model="verifyData.disability">
                                        Persons with Disability</label><br>

                                      <label for="disability-Prefer-not-to-say"><input type="radio"
                                                                                       id="disability-Prefer-not-to-say"
                                                                                       name="verifyPersonal.disability"
                                                                                       value="Prefer not to say"
                                                                                       v-model="verifyData.disability">
                                        Prefer not to say</label><br>
                                      <span class="text-sm text-danger">{{ errors.disability }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group radiotext ">
                                    <label for="veteranstatus" class="form-label"><strong>Veteran Status</strong> <sup
                                        class="text-danger">*</sup></label>
                                    <div class="form-group">
                                      <div>

                                        <label for="veteran"><input type="radio" id="veteran"
                                                                    name="verifyPersonal.veteran_status" value="Veteran"
                                                                    v-model="verifyData.veteran_status"> Veteran</label><br>
                                      </div>
                                      <div>

                                        <label for="non-Veteran"> <input type="radio" id="non-Veteran"
                                                                         name="verifyPersonal.veteran_status"
                                                                         value="Non-Veteran"
                                                                         v-model="verifyData.veteran_status">
                                          Non-Veteran</label><br>
                                      </div>
                                      <div>

                                        <label for="Active-or-Reserve"> <input type="radio" id="Active-or-Reserve"
                                                                               name="verifyPersonal.veteran_status"
                                                                               value="Active or Reserve"
                                                                               v-model="verifyData.veteran_status">
                                          Active or Reserve</label><br>

                                      </div>
                                      <div>

                                        <label for="vet-prefer-not-to-say"> <input type="radio"
                                                                                   id="vet-prefer-not-to-say"
                                                                                   name="verifyPersonal.veteran_status"
                                                                                   value="Prefer not to say"
                                                                                   v-model="verifyData.veteran_status">
                                          Prefer not to say</label><br>
                                      </div>

                                      <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </b-modal>
                        </div>
                        <!-- <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>-->
                      </div>
                    </div>

                    <div class="row" v-if="fields.demographic">
                      <div class="col-md-6" v-if="fields.demographic.race">
                        <label>{{ fields.demographic.race.label || 'Race'}}</label>
                        <!-- <span
                        class="mb-0"
                        v-for="(diversity,i) in diversities" :key="i">
                            <p v-if="diversity.id == form.members[0].diversity_id">
                            {{diversity.name}}
                            </p>
                        </span> -->
                        <span class="mb-0">{{ form.diversity_txt }}</span>
                      </div>
                      <div class="col-md-6" v-if="fields.demographic.ethnicity">
                        <label>{{ fields.demographic.ethnicity.label || 'Ethnicity'}}</label>
                        <span>{{ form.ethnicity }}</span>
                      </div>
                      <div class="col-md-6" v-if="fields.demographic.disability_status">
                        <label>{{ fields.demographic.disability_status.label || 'Disability'}}</label>
                        <span>{{ form.disability }}</span>
                      </div>
                      <div class="col-md-6" v-if="fields.demographic.veteran_status">
                        <label>{{ fields.demographic.veteran_status.label || 'Veteran Status'}}</label>
                        <span>{{ form.veteran_status }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.primary_area_interest">
                          <label>Primary Role of Interest</label>
                          <span>{{ form.members[0].primary_area_interest || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.category">
                            <label>Category</label>
                            <span>{{ form.members[0].category_name || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.hear_about">
                            <label>How did you hear about {{ getSiteProps('general.site_name') }} ?</label>
                            <span>{{ form.members[0].hear_about || "N/A" }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6" v-if="fields.demographic.issf">
                            <label>ISSF License Certification Number</label>
                            <span>{{ form.members[0].issf || "N/A" }}</span>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-md-9">
                        <h3>{{ wiz_step3 && fields.contact.tab_title ? fields.contact.tab_title : 'Contact Information'}}</h3>
                      </div>
                      <div class="col-md-3 text-right">
                        <div class="modal-btn">
                          <!-- <b-button v-b-modal.modal-contact-edit><img
                              v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit
                          </b-button> -->
                          <b-modal ok-only ref="modal"
                                   @show="resetModal"
                                   ok-title="Update"
                                   @hidden="resetModal" @ok="handlePersonalContactEditOk" id="modal-contact-edit"
                                   size="lg" centered title="Contact Information">
                            <div class="popupbody">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="email" class="form-label">Email <sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="45" id="email"
                                           v-model="verifyData.email" name="verifyData.email">
                                    <span class="text-sm text-danger">{{ errors.email }}</span>
                                  </div>
                                  <div class="form-group">
                                    <label for="retype_email" class="form-label">Retype Email <sup
                                        class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="45" id="retype_email"
                                           v-model="verifyData.retype_email" name="verifyData.retype_email">
                                    <span class="text-sm text-danger">{{ errors.retype_email }}</span>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="verifyData_firstname" class="form-label">Mailing Address <sup
                                        class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="100" id="verifyData_address1"
                                           v-model="verifyData.address1" name="verifyData.address1">
                                    <span class="text-sm text-danger">{{ errors.address1 }}</span>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="verifyData_firstname" class="form-label">Address 2</label>
                                    <input type="text" class="form-control" maxlength="45" id="verifyData_address2"
                                           v-model="verifyData.address2" name="verifyData.address2">
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="Country" class="form-label">Country <sup
                                        class="text-danger">*</sup></label>
                                    <select class="form-control" id="verifyData_country" v-model="verifyData.country"
                                            name="verifyData.country">
                                      <option v-bind:value="listOfCountry.country"
                                              v-for="listOfCountry in listOfCountries" :key="listOfCountry.country"
                                              :selected="verifyData.country == listOfCountry.country">
                                        {{ listOfCountry.country }}
                                      </option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.country }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 " v-if="verifyData.country == 'USA' ">
                                  <div class="form-group">
                                    <label for="state_code" class="form-label">State <sup
                                        class="text-danger">*</sup></label>
                                    <select class="form-control" id="verifyData_state_code"
                                            v-model="verifyData.state_code" name="verifyData.state_code">
                                      <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                              :value="listOfState.state_code"
                                              :selected="verifyData.state_code == listOfState.state_code">
                                        {{ listOfState.state }}
                                      </option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.state_code }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6" v-else>
                                  <div class="form-group">
                                    <label for="state_code" class="form-label">Other State <sup
                                        class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="45" v-model="verifyData.state"
                                           name="verifyData.state">
                                    <span class="text-sm text-danger">{{ errors.state }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 ">
                                  <div class="form-group">
                                    <label for="city" class="form-label">City <sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="45" id="verifyData_city"
                                           v-model="verifyData.city" name="verifyData.city">
                                    <span class="text-sm text-danger">{{ errors.city }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 ">
                                  <div class="form-group">
                                    <label for="zip" class="form-label">Postal Code <sup
                                        class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" maxlength="15" id="verifyData_zip"
                                           v-model="verifyData.zip" name="verifyData.zip">
                                    <span class="text-sm text-danger">{{ errors.zip }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 ">
                                  <div class="form-group ">
                                    <div class="phone-nu-wrapper">
                                      <label for="verifyData_phone_1" class="form-label">Home phone with Country Code
                                        <sup class="text-danger">*</sup></label>
                                      <vue-tel-input id="verifyData_phone_1" v-model="verifyData.phone_1" ref="phone1"
                                                     maxlength="20" v-bind="homeProps"
                                                     :validCharactersOnly="true"></vue-tel-input>
                                      <!-- <input type="text" class="form-control" id="country_code_h" name="country_code_h" placeholder="+1" v-mask="'(###)'">
                                                                             <input type="tel" class="form-control" id="phone_1" v-model="form.phone_1"  name="form.phone_1" v-mask="'###-####'">-->
                                      <span class="text-sm text-danger">{{ errors.phone_1 }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <div class="phone-nu-wrapper">
                                      <label for="verifyData_phone_2" class="form-label">Mobile with Country Code  <sup
                                          class="text-danger">*</sup></label>
                                      <vue-tel-input id="verifyData_phone_2" v-model="verifyData.phone_2" ref="phone2"
                                                     maxlength="20" v-bind="mobileProps"
                                                     :validCharactersOnly="true"></vue-tel-input>
                                      <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1" v-mask="'(###)'">
                                                                            <input type="tel" class="form-control" id="phone_2" v-model="form.phone_2"  name="form.phone_2" v-mask="'###-####'">-->
                                      <span class="text-sm text-danger">{{ errors.phone_2 }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-modal>
                        </div>
                        <!-- <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>-->
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                          <label>{{ wiz_step3 && fields.contact.homephone.label ? fields.contact.homephone.label : 'Phone' }}</label>
                          <span>{{ form.phone_1 || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>{{ wiz_step3 && fields.contact.mobilephone.label ? fields.contact.mobilephone.label : 'Mobile' }}</label>
                          <span>{{ form.phone_2 || 'N/A' }}</span>
                      </div>
                      <div class="col-md-12">
                          <label>Email</label>
                          <span>{{ form.email || 'N/A'}}</span>
                      </div>
                      <div class="col-md-12" v-show="form.address_type">
                          <label>Address Type</label>
                          <span>{{ form.address_type || 'N/A' }} Address</span>
                      </div>
                      <div class="col-md-6">
                          <label>{{ wiz_step3 && fields.contact.address1.label ? fields.contact.address1.label : 'Mailing Address' }}</label>
                          <span>{{ form.address1 || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>{{ wiz_step3 && fields.contact.address2.label ? fields.contact.address2.label : 'Address 2' }}</label>
                          <span>{{ form.address2 || 'N/A' }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>City</label>
                          <span>{{ form.city || 'N/A'}}</span>
                      </div>
                      <div class="col-md-6">
                          <label>State</label>
                          <span v-if="form.country == 'USA'">{{
                                  form.state_code
                              }}</span>
                          <span v-else>{{ form.state }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>Postal Code</label>
                          <span>{{ form.zip }}</span>
                      </div>
                      <div class="col-md-6">
                          <label>Country</label>
                          <span>{{ form.country || 'N/A' }}</span>
                      </div>
                      <template v-if="form.address_type == 'Work'">
                          <div class="col-md-6" v-show="form.company_name">
                              <label>Company Name</label>
                              <span>{{ form.company_name || 'N/A' }}</span>
                          </div>
                          <div class="col-md-6" v-show="form.company_phone">
                              <label>Company Phone with Extension</label>
                              <span>{{ form.company_phone  || 'N/A'}} | Ext: {{ form.company_phone_extension || 'N/A'}}</span>
                          </div>
                      </template>
                    </div>
                  </div>

                </div>
                <div class="col-md-6">
                  <div v-if="form.members.length > 0">
                    <template v-for="(family_member, findex) in form.familyMembers">
                      <div class="card-body card" :key="findex">
                          <div class="row">
                              <div class="col-md-9">
                                  <h3>{{ family_member.relation == 1 ? 'Spouse' : 'Child' }} Information</h3>
                              </div>
                              <div class="col-md-3 text-right">

                                <!-- <div class="modal-btn">
                                  <b-button @click.prevent="editFamily(findex)"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                                </div> -->

                              </div>
                          </div>
                          <div class="row">
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.firstName.label ? fields.family.popup.firstName.label : 'First Name' }}</label>
                                  <span>{{ family_member.first_name }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.middleInitial.label ? fields.family.popup.middleInitial.label : 'Middle Initial' }}</label>
                                  <span>{{ family_member.middle_name || 'N/A' }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.LastName.label ? fields.family.popup.LastName.label : 'Last Name' }}</label>
                                  <span>{{ family_member.last_name }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.relation.label ? fields.family.popup.relation.label : 'Relationship' }}</label>
                                  <span>{{ family_member.relation == 1 ? 'Spouse' : 'Child' }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.birthDay.label ? fields.family.popup.birthDay.label : 'Birthday' }}</label>
                                  <span> {{ family_member.dob }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.gender.label ? fields.family.popup.gender.label : 'Gender' }}</label>
                                  <span>{{ family_member.gender }}</span>
                              </div>
                              <div class="col-sm-12">
                                  <label>{{ wiz_step3 && fields.family.popup.email.label ? fields.family.popup.email.label : 'Email' }}</label>
                                  <span>{{ family_member.email }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.homephone.label ? fields.family.popup.homephone.label : 'Home Phone' }}</label>
                                  <span>{{ family_member.phone_1 }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                  <label>{{ wiz_step3 && fields.family.popup.mobile.label ? fields.family.popup.mobile.label : 'Mobile Phone' }}</label>
                                  <span>{{ family_member.phone_2 || 'N/A' }}</span>
                              </div>
                              <div class="col-md-6">
                                  <label>{{ wiz_step3 && fields.family.popup.race.label ? fields.family.popup.race.label : 'Race' }}</label>
                                  <span>{{ family_member.diversity_txt || 'N/A' }}</span>
                              </div>
                              <div v-if="wiz_step3 && fields.family.popup.clubandteam" class="col-md-6">
                                <label>{{ subdomain != 'shooting' ? wiz_step3 && fields.family.popup.clubandteam.label : 'Club / Team' }}</label>
                                <ul v-if="family_member.multiple_affiliates_arr.length > 0" class="pl-3">
                                  <li v-for="(item, k) in clubOptions.filter(item => family_member.multiple_affiliates_arr.map(str => parseInt(str, 10)).includes(item.id)).map(item => item.text)" :key="'v-club'+k">
                                    {{ item }}
                                  </li>
                                </ul>
                                <span v-else>{{ 'N/A' }}</span>
                              </div>
                              <div v-if="wiz_step3 && fields.family.popup.home_federation" class="col-md-6">
                                <div class="form-group">
                                  <label class="form-label">{{ fields.family.popup.home_federation.label? fields.family.popup.home_federation.label : 'Federation' }}</label>
                                  <span>{{ family_member.home_federation || "N/A" }}</span>
                                </div>
                              </div>
                              <div v-if="wiz_step3 && fields.family.popup.skier_license" class="col-md-6">
                                <div class="form-group">
                                  <label class="form-label">{{ fields.family.popup.skier_license.label ? fields.family.popup.skier_license.label : 'Foreign Federation ID' }}</label>
                                  <span>{{ family_member.skier_license || "N/A" }}</span>
                                </div>
                              </div>
                              <div class="col-md-6" v-if="family_member.first_choice_txt">
                                <label>{{ wiz_step3 && fields.family.popup.sport_discipline.first_choice.label ? fields.family.popup.sport_discipline.first_choice.label : 'First Choice'}}</label>
                                <span>{{ family_member.first_choice_txt }}</span>
                              </div>
                              <div v-if="wiz_step3 && fields.family.popup.sport_discipline.second_choice" class="col-md-6">
                                <label>{{ wiz_step3 && fields.family.popup.sport_discipline.second_choice.label ? fields.family.popup.sport_discipline.second_choice.label : 'Second Choice'}}</label>
                                <span>{{ family_member.second_choice_txt }}</span>
                              </div>
                              <div class="col-md-6" v-if="family_member.displines_names">
                                  <label>{{ wiz_step3 && fields.general.sport_discipline && fields.general.sport_discipline.first_choice && fields.general.sport_discipline.first_choice.label ? fields.general.sport_discipline.first_choice.label : 'Disciplines' }}</label>
                                  <ul v-if="family_member.displines_names" class="pl-3">
                                      <li v-for="(item, k) in family_member.displines_names" :key="'v-disp'+k">
                                          {{ item }}
                                      </li>
                                  </ul>
                              </div>
                            <div class="col-md-6" v-if="wiz_step3 && fields.family.popup.ethnicity">
                              <label>{{ wiz_step3 && fields.family.popup.ethnicity.label ? fields.family.popup.ethnicity.label : 'Ethnicity'}}</label>
                              <span>{{ family_member.ethnicity }}</span>
                            </div>
                            <div class="col-md-6" v-if="wiz_step3 && fields.family.popup.disability_status">
                              <label>{{ wiz_step3 && fields.family.popup.disability_status.label ? fields.family.popup.disability_status.label : 'Disability'}}</label>
                              <span>{{ family_member.disability }}</span>
                            </div>
                            <div class="col-md-6" v-if="wiz_step3 && fields.family.popup.veteran_status">
                              <label>{{ wiz_step3 && fields.family.popup.veteran_status.label ? fields.family.popup.veteran_status.label : 'Veteran Status'}}</label>
                              <span>{{ family_member.veteran_status }}</span>
                            </div>
                          </div>
                      </div>
                    </template>
                  </div>
                </div>


              </div>
              <div v-show="wiz_step4" class="col-md-12 mb-view-pl mb-view-pr">
                <div class="card waiver" v-for="(waiver, waiver_index) in waiverData" :key="'waiver_'+waiver_index">
                  <div :id="waiver_index" v-if="waiver.user_info.length">
                    <div v-show="waiversList[waiver_index] == true" :data-value="waiversList[waiver_index]">
                      <div v-if="waiver.type == 'SafeSport'" class="card-header register-title">
                        <h3>Safe Sport Acknowledgment </h3>
                      </div>
                      <div v-else-if="membership.member_type_id == 4 && waiver.type != 'SafeSport'" class="card-header register-title">
                        <h3>Club Agreement </h3>
                      </div>
                      <div v-else class="card-header register-title">
                        <h3>Participant Waiver and Release of Liability, Assumption of Risk and Indemnity Agreement</h3>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <form @submit.prevent="waiverFormSubmit" ref="waiverFormSubmit" name="waiverFormSubmit" method="post">
                            <div class="col-md-12 text-justify">
                              <div class="text-center mb-1" v-if="waiver.type != 'SafeSport'">
                                <strong>This <span v-if="membership.member_type_id == 4">club agreement</span>
                                  <span v-else>waiver</span> is for your membership effective from 
                                  <span v-if="membership.lifetime">
                                    {{ waiver.user_info[0].start_date }} till Lifetime
                                  </span>
                                  <span v-else>{{ waiver.user_info[0].start_date }} to {{ waiver.user_info[0].expires_at }}</span>
                                </strong>
                              </div>
                              <!--                                                                        <div class="text-center mb-1" v-if="membership.member_type_id != 4"><strong>Member name : {{ waiver.user_info.member_name }}</strong></div>-->
                              <!--                                                                        <div  class="text-center mb-1" v-else><strong>Club name : {{ verifyClubInfoData.name }}</strong></div>-->
                              <div v-html="waiver.waivers_content" class="mb-5"></div>
                              <div v-if="membership.member_type_id == 4">
                                <span class="mb-2" style="display:block;">I hereby acknowledge that:</span>
                                <ul>
                                  <li>I am authorized to sanction an event on behalf of the Local Organizing Club (LOC); and</li>
                                  <li>I have reviewed the sanctioning agreement and that the LOC agrees to its terms.</li>
                                </ul>
                              </div>
                              <div class="" v-for="(waiver_user_info, waiver_user_info_index) in waiver.user_info" :key="waiver_user_info_index">
                                <input type="checkbox" class="" :id="waiverDataAgree[waiver_index].agree[waiver_user_info_index]" :name="waiverDataAgree[waiver_index].agree[waiver_user_info_index]" v-model="waiverDataAgree[waiver_index].agree[waiver_user_info_index]">
                                <div class="waiverinner">
                                  <input v-if="membership.member_type_id != 4" class="waivertext" maxlength="2" v-on:keypress="isLetter($event)" type="text" :id="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" :name="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" v-model="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials">
                                </div>
                                <label v-if="membership.member_type_id != 4" class="form-check-label dinline">I,
                                  <span v-if="waiver.type == 'Minor' && membership.id == 2 || (waiver.type == 'SafeSport' && membership.id == 2 && waiverData[0].type =='Minor')">
                                                                                    <input class="waivertext fullnameofwaiver" v-on:keypress="isLetter($event)" type="text" :id="waiverData[waiver_index].user_info[waiver_user_info_index].signed_member" :name="waiverData[waiver_index].user_info[waiver_user_info_index].signed_member" v-model="waiverData[waiver_index].user_info[waiver_user_info_index].signed_member">
                                                                                </span>
                                  <span v-else-if="waiver.type == 'Minor'">{{waiver.user_info[waiver_user_info_index].signed_member}}</span>
                                  <span v-else>{{ waiver.user_info[waiver_user_info_index].member_name }}</span>

                                  <span v-if="waiver.type == 'SafeSport'">, have read and accept the {{ prop_general.site_name }} Safesport Acknowledgment dated on: </span>
                                  <span v-else-if="waiver.type == 'Minor'">, accept the above waiver for {{ waiver.user_info[waiver_user_info_index].member_name }} on this date: </span>
                                  <span v-else>, accept the above waiver on this date: </span>
                                  <input type="text" class="datepicks" disabled :value="currentDate"></label>
                                <label v-else class="form-check-label">I
                                  <input type="text" maxlength="2" v-on:keypress="isLetter($event)" :id="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" :name="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" v-model="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials">
                                  have read and accept the {{ prop_general.site_name }} club agreement dated on: <input type="text" class="datepicks" disabled :value="currentDate"></label>
                                <p class="font-13 mt-4 text-danger"><em>Please check the box and initial in the space provided.</em></p>
                                <span class="text-sm text-danger">{{ waiverErrors[waiver_index].agree[waiver_user_info_index] }}</span>
                                <span class="text-sm text-danger">{{ waiverErrors[waiver_index].initials[waiver_user_info_index] }}</span>
                                <span class="text-sm text-danger">{{ waiverErrors[waiver_index].signed_member[waiver_user_info_index] }}</span>
                              </div>
                              <div class="row" v-if="membership.member_type_id == 4">
                                <div class="col-md-12">
                                  <label><strong>Submitted by</strong></label>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="form-label">First Name</label>
                                    <input type="text" class="form-control" maxlength="45" placeholder="Dickerson" name="clubSubmittedBy.first_name" v-model="clubSubmittedBy.first_name">
                                    <span class="text-sm text-danger">{{ waiverClubErrors.club_submitted_first_name }}</span>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="form-label">Last Name</label>
                                    <input type="text" class="form-control" maxlength="45" placeholder="Macdonald" name="clubSubmittedBy.last_name" v-model="clubSubmittedBy.last_name">
                                    <span class="text-sm text-danger">{{ waiverClubErrors.club_submitted_last_name }}</span>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="form-label">Club Position</label>
                                    <input type="text" class="form-control" maxlength="45" placeholder="President" name="clubSubmittedBy.position" v-model="clubSubmittedBy.position">
                                    <span class="text-sm text-danger">{{ waiverClubErrors.club_submitted_position }}</span>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="form-label">Email</label>
                                    <input type="text" class="form-control" maxlength="45" placeholder="" name="clubSubmittedBy.email" v-model="clubSubmittedBy.email">
                                    <span class="text-sm text-danger">{{ waiverClubErrors.club_submitted_email }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <b-modal id="SpouseWaiverInfoModal" title="Spouse Waiver" ok-only>
                <p>Spouses {{ (subdomain == 'shooting') ? ' / Dependent' : '' }} (age 18 and over) will need to log in to their membership dashboard after the completion of the family registration to sign their waiver.</p>
              </b-modal>
              <div v-show="wiz_step5" class="row payment-view">
                <b-overlay :show="showLoader" no-wrap></b-overlay>
                <div class="col-md-6 mb-view-pr mb-view-pl">
                  <div class="card-body card">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Total Amount</h3>
                      </div>
                      <div class="col-md-12">
                        <ul class="list-group mb-3 payment-details">
                          <li class="d-flex justify-content-between lh-sm">
                            <div>
                              <h6 class="my-0"><strong>{{ membership.type | filterBr }}</strong></h6>
                            </div>
                            <span class="text-muted"><strong>${{ membership.fee | fullNumber }}</strong></span>
                          </li>
                          <!--<li class=" d-flex justify-content-between lh-sm">
                          <div>
                          <h6 class="my-0">Affiliate</h6>
                          </div>
                          <span class="text-muted"><strong>$10.00</strong></span>
                          </li>-->
                          <li v-if="donated.amount != ''" class=" d-flex justify-content-between lh-sm">
                            <div class="cartitemcard">
                              <span class="remove" @click="removeDonation()"><i class="fas fa-trash-alt"></i></span>
                              <h6 class="my-0" v-if="donated.type != '' && fields['donation']['disciplines']['options'][donated.type]">{{ fields['donation']['disciplines']['options'][donated.type] }}</h6>
                            </div>

                            <span class="text-muted"><strong>${{ donated.amount }}</strong></span>
                          </li>

                          <hr v-if="cartProductData.length >= 1">
                          <template v-for="(cartProduct,i) in cartProductData">
                            <li v-if="cartProductData.length >= 1"  class="d-flex justify-content-between lh-sm" :key="i">
                              <div class="cartitemcard">
                                <span class="remove" @click="removeFromCart(cartProduct.id)"><i class="fas fa-trash-alt"></i></span>
                                <h6 class="my-0"><strong>{{ cartProduct.name+' '+cartProduct.variant_name  }}  ${{cartProduct.price}} x {{ cartProduct.quantity }}</strong></h6>


                              </div>

                              <span class="text-muted"><strong>${{ cartProduct.total }}</strong></span>
                            </li>
                          </template>
                          <hr>
                          <li class=" d-flex justify-content-between  ">
                            <div class="coupon-inner mb-3">
                              <h6 class="my-0"><strong>Coupon</strong></h6>
                            </div>
                            <div class="">
                              <input type="text" class="form-control coupon-text" v-model="couponApplied.coupon_code"><button @click.prevent="applyCoupon()" class="btn-apply coupon-btn">{{ button.coupon }}</button>
                              <span class="text-sm text-danger">{{ couponErrors.error}}</span>
                              <span class="text-sm text-success">{{ couponErrors.success}}</span>
                            </div>
                          </li>
                          <hr v-if="cartProductData.length >= 1">
                          <li v-if="this.placeOrderData.registration_id >= 1" class="d-flex justify-content-between lh-sm">
                            <div>
                              <h6 class="my-0"><strong>Merchandise Total</strong></h6>
                            </div>
                            <span class="text-muted"><strong>${{ totals.merchandise_total }}</strong></span>
                          </li>
                          <hr v-if="this.placeOrderData.registration_id >= 1">
                          <li v-if="this.totals.shipping_total >= 1" class="d-flex justify-content-between lh-sm">
                            <div>
                              <h6 class="my-0">Shipping Charge - {{ cartProductCount }} Item(s)</h6>
                            </div>
                            <span class="text-muted"><strong>${{ totals.shipping_total }}</strong></span>
                          </li>
<!--                          <li v-if="this.placeOrderData.registration_id >= 1" class="d-flex justify-content-between lh-sm">
                            <div>
                              <h6 class="my-0"><strong>Shipping Charge - Additional Items</strong></h6>
                            </div>
                            <span class="text-muted"><strong>${{ totals.shipping_additional_item }}</strong></span>
                          </li>-->
                          <hr v-if="this.placeOrderData.registration_id >= 1">
                          <li v-if="couponApplied.status == 1" class="d-flex justify-content-between lh-sm">
                            <div>
                              <h6 class="my-0">
                                <strong>
                                  <a href="javascript:void(0);" class="text-danger" @click="removeCoupon()"><i class="fas fa-trash-alt"></i></a>
                                  Discount - {{ couponApplied.text }}
                                </strong>
                              </h6>
                            </div>
                            <span class="text-muted"><strong>-${{ couponApplied.amount.toFixed(2)  }}</strong></span>
                          </li>
                          <hr v-if="couponApplied.status == 1">
                          <li class="d-flex justify-content-between lh-sm">
                            <div>
                              <h6 class="my-0"><strong>Sub Total</strong></h6>
                            </div>
                            <span class="text-muted"><strong>${{ totals.sub_total }}</strong></span>
                          </li>
                          <hr>
                          <template v-if="processingFeePercent">
                            <li class=" d-flex justify-content-between lh-sm">
                                <div class="processing-tooltip">
                                    <h6 class="my-0"><strong>Technology/Processing Fee ({{this.processingFeePercent}}%) <b-button v-b-tooltip.hover title="This small fee enables USA-WSWS to make technology upgrades to enhance your membership experience."><img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></b-button></strong></h6>
                                </div>
                                <span class="text-danger"><strong>${{ processingFee }}</strong></span>
                            </li>
                            <hr>
                          </template>
                          <li class=" d-flex justify-content-between lh-sm">
                            <div>
                              <h6 class="my-0"><strong>Grand Total</strong></h6>
                            </div>
                            <span class="text-danger"><strong>${{ totals.grand_total }}</strong></span>
                          </li>
                        </ul>
                      </div>


                    </div>

                  </div>
                  <div class="card-body card" v-if="membership.member_type_id == 4">
                    <div class="row">
                      <div class="col-md-6">
                        <h3>Club Details</h3>
                      </div>
                      <div class="col-md-6 text-right">
                        <!-- <div class="modal-btn">
                          <b-button v-b-modal.modal-club-edit @click.prevent="personalEditmodal"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                        </div> -->
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label>Club Name</label>
                        <span>{{ verifyClubInfoData.name }}</span>
                      </div>
                      <div class="col-md-6">
                        <label>Club Type</label>
                        <span>{{ clubType[verifyClubInfoData.type] }}</span>
                      </div>
                    </div>
                    <!--                                                    <div class="row">
                                                                            <div class="col-md-6">
                                                                                <label>Club Name</label>
                                                                                <span>{{ verifyClubInfoData.name }}</span>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                 <label>Full Name</label>
                                                                                 <span>{{ verifyClubInfoData.primary_contact.first_name }} {{ verifyClubInfoData.primary_contact.last_name }}</span>
                                                                            </div>
                                                                        </div>-->
                  </div>
                  <div class="card-body card" v-if="membership.member_type_id != 4">
                    <div class="row">
                      <div class="col-md-6">
                        <h3>Member Details</h3>
                      </div>
                      <div class="col-md-6 text-right">
                        <div class="modal-btn">
                          <!-- <b-button v-b-modal.modal-personal-edit @click.prevent="personalEditmodal"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button> -->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <label>First Name</label>
                        <span>{{ form.members[0].first_name || "N/A"}}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>Middle Initial</label>
                        <span>{{ form.members[0].middle_name || "N/A"}}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>Last Name</label>
                        <span>{{ form.members[0].last_name || "N/A"}}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label>
                          <img v-bind:src="'/dist/img/memberregistration/birthday.png'" alt="birthday_icon">
                          {{ wiz_step5 && fields.personal && fields.personal.birthday && fields.personal.birthday.label ? fields.personal.birthday.label : 'Birthday' }}
                        </label>
                        <span>{{ form.members[0].dob  }}</span>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <label><img v-bind:src="'/dist/img/memberregistration/gender.png'" alt="gender_icon">Gender</label>
                        <span>{{ form.members[0].gender || "N/A" }}</span>
                      </div>
                      <!--                                                            <div class="col-md-6 col-sm-6  ">
                                                                                      <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="cityzenship_icon">Citizenship</label>
                                                                                      <span>{{ verifyEditData.members.citizenship }}</span>
                                                                                  </div>-->

                    </div>

                  </div>
                  <!--                                                <div class="card-body card">
                                                                      <div class="row">
                                                                          <div class="col-md-6">
                                                                              <h3>Member 2 Details</h3>
                                                                          </div>
                                                                          <div class="d-flex col-md-6 text-right">
                                                                             <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>
                                                                          <button class="btn-remove"><img v-bind:src="'/dist/img/memberregistration/remove.png'" alt="edit_icon">Remove</button>
                                                                          </div>
                                                                      </div>
                                                                      <div class="row">
                                                                          <div class="col-md-6">
                                                                               <label>First Name</label>
                                                                               <span>William</span>
                                                                          </div>
                                                                          <div class="col-md-6">
                                                                               <label><img v-bind:src="'/dist/img/memberregistration/birthday.png'" alt="birthday_icon">Birthday</label>
                                                                               <span>12/31/2010</span>
                                                                          </div>
                                                                          <div class="col-md-6">
                                                                               <label>Last Name</label>
                                                                               <span>Smith</span>
                                                                          </div>

                                                                          <div class="col-md-6">
                                                                               <label><img v-bind:src="'/dist/img/memberregistration/gender.png'" alt="gender_icon">Gender</label>
                                                                               <span>Male</span>
                                                                          </div>

                                                                           <div class="col-md-6">
                                                                               <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="relationship">Cityzenship</label>
                                                                               <span>child</span>
                                                                          </div>
                                                                          <div class="col-md-6">
                                                                               <label>Club</label>
                                                                               <span>Association of Ohio</span>
                                                                          </div>
                                                                      </div>

                                                                  </div>-->
                </div>
                <div class="col-md-6 mb-view-pl mb-view-pr">
                  <div class="card-body card card-wrapper">
                    <div class="row">
                      <template v-if="paymentForm.visible">
                      <div class="col-md-12 card-info">
                        <h3>Card Information</h3>
                        <div class="text-right">
                          <!--                                                                            <img v-bind:src="'/dist/img/memberregistration/stripe.png'" alt="stripe">-->
                        </div>
                      </div>
                      <template v-if="getSiteProps('general.paymentGateway') == 'stripe'">
                          <div class="col-md-12">
                              <div class="form-group mb-0">
                                  <label class="form-label">Card Number / Expiry Month and Year / CVC<sup class="text-danger">*</sup></label>
                                  <stripe-element-card 
                                      v-show="wiz_step5"
                                      ref="stripeForm"
                                      :pk="stripeKey"
                                      :hidePostalCode="true"
                                      iconStyle="solid"
                                      @token="stripeTokenCreated"
                                      @error="captureStripeErrors"
                                      @element-change="stripeFormChanges"
                                  />
                                  <!-- <span class="text-sm text-danger" v-if="stripeError">{{ stripeError }}</span> -->
                              </div>
                          </div>
                      </template>
                      <template v-else>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Card Number<sup class="text-danger">*</sup></label>
                            <input type="hidden" name="paymentForm.amount" v-model="paymentForm.amount">
                            <input type="hidden" name="paymentForm.registration_id" v-model="paymentForm.registration_id">
                            <input type="text" maxlength="16" placeholder="#### #### #### ####" class="form-control" name="paymentForm.cardNumber" v-model="paymentForm.cardNumber" @keypress="isNumber($event)">
                            <!--                                                                                <input class="form-control" name="paymentForm.cardNumber" v-model="paymentForm.cardNumber" v-cardformat:formatCardNumber>-->
                            <span class="text-sm text-danger">{{ errors.cardNumber}}</span>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <label class="form-label">Expiration Date<sup class="text-danger">*</sup></label>
                          <select class="form-control form-select" id="expiration-month" required="" name="paymentForm.expiration_month" v-model="paymentForm.expiration_month">
                            <option value="">Month</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.expiration_month}}</span>
                        </div>
                        <div class="col-md-3 mp-l-r pl-0 pr-0 col-md-offset-1" style="margin-top: 30px;">
                          <select class="form-select form-control" id="expiration-year" required="" name="paymentForm.expiration_year" v-model="paymentForm.expiration_year">
                            <option value="">Year</option>
                            <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.expiration_year}}</span>
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">CVV/CVC<sup class="text-danger">*</sup></label>
                          <input type="password" class="form-control" name="paymentForm.cvv" maxlength="4" v-model="paymentForm.cvv" @keypress="isNumber($event)">
                          <span class="text-sm text-danger">{{ errors.cvv}}</span>
                        </div>
                      </template>
                      <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                            <input type="text" placeholder="William" class="form-control" id="billing_firstname" name="paymentForm.billing_firstname" v-model="paymentForm.billing_firstname">
                            <span class="text-sm text-danger">{{ errors.billing_firstname}}</span>
                          </div>
                      </div>
                      <div class="col-md-12" v-if="!membership.lifetime && getSiteProps('general.auto_renewal')">
                        <div  class="form-check mt-3">
                          <input type="checkbox" id="autorenewall" class="form-check-input" value="1" v-model="paymentForm.auto_renew">
                          <label for="autorenewall" class="form-check-label light-font">
                            <div v-if="membership.member_type_id != 4">Membership</div><div v-else>Club membership</div> fees will auto-renew and credit card will be charged upon expiration</label>
                          <span class="text-sm text-danger">{{ errors.auto_renew}}</span>
                        </div>


                      </div>
                      <hr />
                      <div class="col-md-12">
                        <h3>Billing Address</h3>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label >Mailing Address<sup class="text-danger">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" name="paymentForm.billing_addressline1" v-model="paymentForm.billing_addressline1">
                          <span class="text-sm text-danger">{{ errors.billing_addressline1}}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>City<sup class="text-danger">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" name="paymentForm.billing_city" v-model="paymentForm.billing_city">
                          <span class="text-sm text-danger">{{ errors.billing_city}}</span>
                        </div>
                      </div>
                      <div class="col-md-6 pr-0" v-if="paymentForm.billing_country == 'USA'">
                        <div class="form-group">
                          <label>State<sup class="text-danger">*</sup></label>
                          <select class="form-control" id="state_code" name="paymentForm.billing_state_code" v-model="paymentForm.billing_state_code">
                            <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code" :selected="paymentForm.billing_state_code == listOfState.state_code">{{ listOfState.state }}</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.billing_state_code}}</span>
                        </div>
                      </div>
                      <div class="col-md-6 pr-0" v-else-if="paymentForm.billing_country == 'Canada'">
                        <div class="form-group">
                          <label>State<sup class="text-danger">*</sup></label>
                          <select class="form-control" id="state_code" name="paymentForm.billing_state" v-model="paymentForm.billing_state">
                            <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state" :selected="paymentForm.billing_state == listOfState.state">{{ listOfState.state }}</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.billing_state}}</span>
                        </div>
                      </div>
                      <div class="col-md-6" v-else>
                        <div class="form-group">
                          <label for="state_code" class="form-label">State</label>
                          <input type="text" class="form-control" maxlength="45" v-model="paymentForm.billing_state" name="paymentForm.billing_state">
                          <span class="text-sm text-danger">{{ errors.billing_state}}</span>
                        </div>
                      </div>
                      <div class="col-md-6 pr-0">
                        <div class="form-group">
                          <label>Zip / Postal Code<sup class="text-danger">*</sup></label>
                          <input type="text"  class="form-control" :maxlength="getSiteProps('general.zip_code_length')" name="paymentForm.billing_zip" v-model="paymentForm.billing_zip">
                          <span class="text-sm text-danger">{{ errors.billing_zip}}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Country<sup class="text-danger">*</sup></label>
                          <select class="form-control" id="country" @change="removeStateCode" v-model="paymentForm.billing_country" name="paymentForm.billing_country">
                            <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :value="listOfCountry.country" :selected="paymentForm.billing_country == listOfCountry.country">{{ listOfCountry.country }}</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.billing_country}}</span>
                        </div>
                      </div>
                      <!-- <div class="col-md-12">
                        <p class="mt-3 font-13" style="font-size:11px;margin-bottom:0;"><i>All transactions also include a 3% processing/technology fee.</i></p>
                      </div> -->
                      <hr />
                      </template>
                      <div class="col-md-12 payment-conf confirmsc">
                        <h3>Confirm</h3>
                        <!-- <p style="font-13">By submitting this information, you agree to the <router-link class-active="active" target="_blank" to="/privacy-policy">privacy policy</router-link> and <router-link target="_blank" class-active="active" to="/terms-and-conditions">Terms &amp; Conditions</router-link> policy, including
                          AUTO-RENEWAL and understand that there are NO REFUNDS. </p> -->
                        <p style="font-13" v-if="prop_confirmation.label" v-html="prop_confirmation.label"></p>
                        <div class="form-check">
                          <input type="checkbox" id="payment_agree" class="form-check-input" v-model="paymentForm.agree" name="paymentForm.agree">
                          <label for="payment_agree" class="form-check-label">  <input type="text" class="chkboxinput" v-model="paymentForm.sign" name="paymentForm.sign" maxlength="2" @keypress="isAlphabets($event)"> 
                          <span :style="{ 'display': 'inline-block' }" v-if="prop_confirmation.agree" v-html="prop_confirmation.agree"></span>
                          </label>
                          <span v-if="prop_confirmation.note" v-html="prop_confirmation.note"></span>
                          <span class="text-sm text-danger">{{ errors.payment_agree}}</span>
                          <span class="text-sm text-danger">{{ errors.payment_sign}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="wiz_step6" class="col-md-12">
                <div class="card-body confirmation">
                  <div class="row">
                    <div class="col-md-12" style="padding:100px 0;" v-if="registration_message_flag">
                        <h3 class="text-center text-success"> {{ this.registration_message_text.title }}</h3>
                        <div class="form-group text-center" v-html="this.registration_message_text.content">
                        
                        </div>
                    </div>
                    <div class="col-md-12" style="padding:100px 0;" v-else>
                      <h3 class="text-center text-success">Thanks for the Registration. </h3>
                      <div class="form-group text-center">
                        <!--                                                <label class="form-label">Transaction Id</label>-->
                        <span>{{ paymentData }}</span>
                        <p>You will receive an email shortly regarding this transaction along with your receipt and membership information.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-2">
                <div v-if="wiz_step < 6 " class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" v-if="wiz_step != 1 && wiz_step != 6 && wiz_step != 2 " @click.prevent="previousStep"
                          class="btn btn-outline-primary cancelbtn btn-previious  ">Previous Step
                  </button>
                  <button type="button" v-if="(wiz_step == 1 && step == 1) || (wiz_step >= 3)"
                          @click.prevent="wizardNextstep"
                          class="btn btn-secondary btnnextstp btndisabled btn-next-success">{{ button.nextStepButton }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer style="margin-bottom:40px;">
      <!-- <strong>Copyright © 2019 - 2022 <a href="http://sports.integrass.com">Integrass Sports</a>.</strong>
            All rights reserved.
            <div class="float-right d-none d-sm-inline-block"><b>Version</b> 3.0.4
            </div> -->
    </footer>
  </div>
</template>

<script>

import moment from "moment";
import DatePick from 'vue-date-pick';

const validateMembershipSelected = radio => {
  if (!radio) {
    return { valid: false, error: "Please select the member type" };
  }
  return { valid: true, error: null };
};

const validateWaiverInitials = name => {
  if (!name) {
    return { valid: false, error: "Please enter your Initials to proceed" };
  } else {
    if(name.length != 2) {
      return { valid: false, error: "You must enter two letters to initial" };
    }
  }
  return { valid: true, error: null };
};
const validateWaiverCheckbox = checkbox => {
  console.log("resss="+checkbox);
  if (!checkbox) {
    return { valid: false, error: "Please acknowledge by checking the box" };
  }
  return { valid: true, error: null };
};
const validateRadio = radio => {
  if (!radio) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateSignedUserName = name => {
  if (name == undefined) {
    return { valid: false, error: "Please enter your name" };
  } else if(name == "") {
    return { valid: false, error: "Please enter your name" };
  }
  return { valid: true, error: null };
};
const validateName = (name, fieldTxt) => {
  if (!name.length || name == "") {
    return { valid: false, error: fieldTxt + " is required" };
  }
  return { valid: true, error: null };
};
const validateStateName = name => {
  console.log('v state');
  console.log(name);
  if (name == null || name == "") {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateCardNumber = (cardNumber, fieldTxt) => {
  if (!cardNumber.length || cardNumber == "") {
    return { valid: false, error: fieldTxt+ " is required" };
  }
  if ((cardNumber.replace(/\s/g,'')).length < 14) {
    return { valid: false, error: "Card number must be greater than 13 digits" };
  }
  return { valid: true, error: null };
};
const validateCvv = (cvv, fieldTxt) => {
  if (!cvv.length || cvv == "") {
    return { valid: false, error: fieldTxt+ " is required" };
  }
  if (!cvv.match(/^[0-9]{3,4}$/)) {
    return { valid: false, error: "CVV must be 3 or 4 digits"};
  }
  return { valid: true, error: null };
};
const validateSelect = (select, fieldTxt) => {
  console.log('m select');
  console.log(select);
  if (select == "" || select == null) {
    return { valid: false, error: fieldTxt + " is required" };
  }
  return { valid: true, error: null };
};
const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "This field is required"};
  }
  if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
    return {valid: false, error: "Please enter a valid email."};
  }
  return { valid: true, error: null };
};
const validateDob = dob => {
  if (!dob.length) {
    return { valid: false, error: "This field is required" };
  }
  /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
  return { valid: false, error: "Please, enter a valid data of birth." };
  }*/
  return { valid: true, error: null };
};
const validateGender = gender => {
  if (!gender) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateZip = (zipCode, country) => {

    if(!zipCode.length)
    {
         return { valid: false, error: "Zip code is required" };
    }
    else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
        return { valid: false, error: "Zip Code Should Be 5 Number Values for USA." };
    }
    else if(zipCode.length < 5 ){
         return { valid: false, error: "Zip code should be 5 character" };
    }
      return { valid: true, error: null };
};
// import navigation from "../components/Navigation";
// import sidebar from "../components/Sidebar";
import Donation from "./Donation";
import personalinformation from "./register/personalinformation";
import generalinfo from "./register/generalinfo";
import demographicinformation from  "./register/demographicinformation";
import contactinfo from "./register/contactinfo";
import familymembers from "./register/familymembers";
import Merchandise from "./Merchandise";
import axios from 'axios';
import Vue from "vue";
// import Confirmation from "./Confirmation";

import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  name: "Renew",

  data() {
    return {
      priceFetching: true,
      freeRegistration:false,
      newRegId:'',
      clubOptions:[],
      stripeToken: null,
      stripeError: "",
      stripeValidate: false,
      processingFeePercent: 0,
      processingFee: 0,
      wiz_step: 1,
      step :1,
      wiz_step1:true,
      wiz_step2: false,
      wiz_step3: false,
      wiz_step4: false,
      wiz_step5: false,
      wiz_step6: false,
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
      step8: false,
      currentPath: '',
      memberTypeUrl : this.basePath + "api/getMemberType",
      registerTotalstep: 7,
      verifyData: {
        "member_type_id": '',
        "email": '',
        "retype_email": '',
        "printed_magazine":false,
        "address1": '',
        "address2": '',
        "zip": '',
        "state_code": '',
        "city": '',
        "country": '',
        "phone_1": '',
        "phone_2": '',
        "diversity_id": '',
        "membership_id": '',
        "safe_sport_status": '',
        "safe_sport_user_name": '',
        "safe_sport_email": '',
        "first_choice": '',
        "second_choice": '',
        "federation": '',
        "members": [{
          first_name: '',
          last_name: '',
          middle_name: '',
          suffix: '',
          gender: '',
          dob: '',
          citizenship: '',
          membership_id: '',
          contact_type: '',
          relation: 0,
          diversity_id: '',
          ethnicity: '',
          disability: '',
          veteran_status: ''
        }],
        'donation_amount': 0.00,
        'disability': '',
        'veteran_status': '',
        ethnicity: '',
      },
      donationData: {
        donation_amount: 0.00,
        donation_discipline: '',
      },
      valid: false,
      diversities: [],
      disciplines:[],
      memberTypes: [],
      button: {
        nextButton: 'Next',
        nextStepButton: 'Next Step',
        coupon: 'Apply Coupon'
      },
      regId: 1,
      form: {
        "current_member_id": "",
        "renew": 1,
        "member_type_id": '',
        "email": '',
        "retype_email": '',
        "printed_magazine":false,
        'address_type': '',
        "address1": '',
        "address2": '',
        "zip": '',
        "state_code": '',
        "city": '',
        "country": '',
        "phone_1": '',
        "phone_2": '',
        "company_name": "",
        "company_phone": "",
        "company_phone_extension": "",
        "diversity_id": '',
        "membership_id": '',
        "safe_sport_status": '',
        "safe_sport_user_name": '',
        "safe_sport_email": '',
        "first_choice": '',
        "second_choice": '',
        "federation": '',
        "registration_cost": null,
        "members": [{
          title: '',
          first_name: '',
          last_name: '',
          middle_name: '',
          suffix: '',
          gender: '',
          dob: '',
          age: '',
          citizenship: '',
          membership_id: '',
          contact_type: '',
          relation: 0,
          diversity_id: '',
          diversity_txt: '',
          ethnicity: '',
          disability: '',
          veteran_status: '',
          primary_area_interest: '',
          category: '',
          category_name: '',
          hear_about: '',
          issf: '',
          cmp_no: '',
          nra_no: '',
          sasp_no: '',
          sctp_no: '',
          first_choice: '',
          first_choice_txt: '',
          second_choice: '',
          second_choice_txt: '',
          home_federation: 'USA',
          skier_license: ""
        }],
        'donation_amount': 0.00,
        'disability': '',
        'veteran_status': '',
        ethnicity: '',
        affiliate_club: {
          club: '',
          multiple_club:'',
          club_name: '',
          new_club_name: '',
          new_club_email: '',
          new_club_phone: ''
        },
        new_club_name: '',
        new_club_email: '',
        new_club_phone: '',
        categories: [],
        disciplines: [],
        disciplines_names: [],
        diversity_txt: "",
        familyMembers: [],
        removedFamilyMem: [],
        coupon_id: null,
        discount_amount: 0.00,
        next_renewal_period: ""
      },
      age: '',
      donated: {
        "type": '',
        "amount": 0.00,
      },
      paymentData: [],
      registration_message_flag: false,
      registration_message_text: [],
      races: [
        "American India or Alaska Native",
        "Asian",
        "Black/African American",
        "Hispanic or Latino",
        "Native Hawaiian or Pacific Islander",
        "White (Not of Hispanic Origin)",
        "Two or More Races"
      ],
      paymentForm: {
        "stripeToken": "",
        "renew": 1,
        "cardNumber": '',
        "expiration_year": '',
        "expiration_month": '',
        "cvv": '',
        "amount": '',
        "owner": "",
        "name_on_card": "",
        "registration_id": '',
        "billing_firstname": "",
        "billing_lastname": "",
        "billing_company": "",
        "billing_addressline1": "",
        "billing_zip": "",
        "billing_state": "",
        "billing_state_code": "",
        "billing_city": "",
        "billing_country": "",
        "billing_phone1": "",
        "upgrade":0,
        "old_registration_id":'',
        auto_renew: 1,
        visible: true,
        zero_amount: false,
        coupon_id: ""
      },
      merchandiseData: [],
      orderPlaceData: {
        "registration_id": '',
      },
      placeOrderData: {
        "registration_id": '',
      },
      couponApplied: {
        "status": 0,
        "coupon_code": '',
        "amount": 0.00,
      },
      totals: {
        merchandise_total: 0.00,
        shipping_total: 0.00,
        shipping_first_item: 0.00,
        shipping_additional_item: 0.00,
        sub_total: 0.00,
        grand_total: 0.00,
      },
      membership: {
        "id": '',
        "type": '',
        "fee": 0.00,
        age_criteria: "",
        member_type_id: '',
        lifetime: false
      },
      errors: [],
      currentWaiver: 0,
      waiverData: [{
        agree:[],
        user_info: [{
          start_data: '',
          expires_at: ''
        }]
      }],
      waiverDataAgree: [{
        agree: []
      },{
        agree: []
      },{
        agree: []
      },{
        agree: []
      }],
      waiverType: true,
      waiverErrors: [{
        agree: [],
        initials: [],
        signed_member: []
      },{
        agree: [],
        initials: [],
        signed_member: []
      },{
        agree: [],
        initials: [],
        signed_member: []
      }],
      listOfCountries: [
        {country: 'USA'},
        {country: 'Afghanistan'}, 
        {country: 'Åland Islands'}, 
        {country: 'Albania'}, 
        {country: 'Algeria'}, 
        {country: 'American Samoa'}, 
        {country: 'AndorrA'}, 
        {country: 'Angola'}, 
        {country: 'Anguilla'}, 
        {country: 'Antarctica'}, 
        {country: 'Antigua and Barbuda'}, 
        {country: 'Argentina'}, 
        {country: 'Armenia'}, 
        {country: 'Aruba'}, 
        {country: 'Australia'}, 
        {country: 'Austria'}, 
        {country: 'Azerbaijan'}, 
        {country: 'Bahamas'}, 
        {country: 'Bahrain'}, 
        {country: 'Bangladesh'}, 
        {country: 'Barbados'}, 
        {country: 'Belarus'}, 
        {country: 'Belgium'}, 
        {country: 'Belize'}, 
        {country: 'Benin'}, 
        {country: 'Bermuda'},
        {country: 'Bhutan'},
        {country: 'Bolivia'},
        {country: 'Botswana'},
        {country: 'Brazil'},
        {country: 'Britain'},
        {country: 'British West Indies'},
        {country: 'Brunei Darussalam'},
        {country: 'Bulgaria'},
        {country: 'Burma'},
        {country: 'Burundi'},
        {country: 'Cambodia'},
        {country: 'Cameroon'},
        {country: 'Canada'},
        {country: 'Cayman Islands'},
        {country: 'Central African Rep.'},
        {country: 'Chad'},
        {country: 'Chile'},
        {country: 'China'},
        {country: 'Colombia'},
        {country: 'Congo'},
        {country: 'Costa Rica'},
        {country: 'Cuba'},
        {country: 'Cyprus'},
        {country: 'Czech Republic'},
        {country: 'Czechoslovakia'},
        {country: 'Denmark'},
        {country: 'Dominican Republic'},
        {country: 'Ecuador'},
        {country: 'Egypt'},
        {country: 'El Salvador'},
        {country: 'England'},
        {country: 'Equatorial Guinea'},
        {country: 'Estonia'},
        {country: 'Ethiopia'},
        {country: 'Falkland Islands'},
        {country: 'Faroe Islands'},
        {country: 'Finland'},
        {country: 'France'},
        {country: 'French West Indies'},
        {country: 'Gabon'},
        {country: 'Gambia'},
        {country: 'Georgia'},
        {country: 'Germany'},
        {country: 'Ghana'},
        {country: 'Gibraltar'},
        {country: 'Great Britain'},
        {country: 'Greece'},
        {country: 'Greenland'},
        {country: 'Grenada'},
        {country: 'Guadeloupe'},
        {country: 'Guam'},
        {country: 'Guatemala'},
        {country: 'Guinea'},
        {country: 'Guyana'},
        {country: 'Haiti'},
        {country: 'Honduras'},
        {country: 'Hong Kong'},
        {country: 'Hungary'},
        {country: 'Iceland'},
        {country: 'India'},
        {country: 'Indonesia'},
        {country: 'Iran'},
        {country: 'Iraq'},
        {country: 'Iraq-Saudi Arabia Neutra'},
        {country: 'Ireland'},
        {country: 'Israel'},
        {country: 'Italy'},
        {country: 'Ivory Coast'},
        {country: 'Jamaica'},
        {country: 'Japan'},
        {country: 'Jordan'},
        {country: 'Kazakhstan'},
        {country: 'Kenya'},
        {country: 'Korea'},
        {country: 'Kuwait'},
        {country: 'Kyrgyzstan'},
        {country: 'Laos'},
        {country: 'Latvia'},
        {country: 'Lebanon'},
        {country: 'Lesotho'},
        {country: 'Liberia'},
        {country: 'Libya'},
        {country: 'Liechtenstein'},
        {country: 'Lithuania'},
        {country: 'Luxembourg'},
        {country: 'Macau'},
        {country: 'Madagascar'},
        {country: 'Malaysia'},
        {country: 'Mali'},
        {country: 'Malta'},
        {country: 'Mauritania'},
        {country: 'Mauritius'},
        {country: 'Mexico'},
        {country: 'Moldova'},
        {country: 'Monaco'},
        {country: 'Morocco'},
        {country: 'Mozambique'},
        {country: 'Namibia'},
        {country: 'Nepal'},
        {country: 'Netherlands'},
        {country: 'Netherlands Antilles'},
        {country: 'New Zealand'},
        {country: 'Nicaragua'},
        {country: 'Niger'},
        {country: 'Nigeria'},
        {country: 'North Korea'},
        {country: 'Northern Ireland'},
        {country: 'Norway'},
        {country: 'Oman'},
        {country: 'Pakistan'},
        {country: 'Palau'},
        {country: 'Panama'},
        {country: 'Papua New Guinea'},
        {country: 'Paraguay'},
        {country: 'Peru'},
        {country: 'Philippines'},
        {country: 'Poland'},
        {country: 'Portugal'},
        {country: 'Qatar'},
        {country: 'Romania'},
        {country: 'Russia'},
        {country: 'Rwanda'},
        {country: 'Saint Helena'},
        {country: 'Saint Vincent'},
        {country: 'San Marino'},
        {country: 'Saudi Arabia'},
        {country: 'Senegal'},
        {country: 'Serbia'},
        {country: 'Sierra Leone'},
        {country: 'Singapore'},
        {country: 'Slovakia'},
        {country: 'Somalia'},
        {country: 'South Africa'},
        {country: 'South Korea'},
        {country: 'Spain'},
        {country: 'Sri Lanka'},
        {country: 'Sudan'},
        {country: 'Suriname'},
        {country: 'Swaziland'},
        {country: 'Sweden'},
        {country: 'Switzerland'},
        {country: 'Syria'},
        {country: 'Tahiti'},
        {country: 'Taiwan'},
        {country: 'Tajikistan'},
        {country: 'Tanzania'},
        {country: 'Thailand'},
        {country: 'Togo'},
        {country: 'Trinidad West Indies'},
        {country: 'Tunisia'},
        {country: 'Turkey'},
        {country: 'Turkmenistan'},
        {country: 'Uganda'},
        {country: 'Ukraine'},
        {country: 'United Arab Emirates'},
        {country: 'United Kingdom'},
        {country: 'Uruguay'},
        {country: 'Uzbekistan'},
        {country: 'Venezuela'},
        {country: 'Vietnam'},
        {country: 'Virgin Islands'},
        {country: 'Wales'},
        {country: 'Yemen'},
        {country: 'Yugoslavia'},
      ],
      listOfStates: [
        {state_code: 'AL', state: 'Alabama'},
        {state_code: 'AK', state: 'Alaska'},
        {state_code: 'AZ', state: 'Arizona'},
        {state_code: 'AR', state: 'Arkansas'},
        {state_code: 'CA', state: 'California'},
        {state_code: 'CO', state: 'Colorado'},
        {state_code: 'CT', state: 'Connecticut'},
        {state_code: 'DE', state: 'Delaware'},
        {state_code: 'DC', state: 'District Of Columbia'},
        {state_code: 'FL', state: 'Florida'},
        {state_code: 'GA', state: 'Georgia'},
        {state_code: 'HI', state: 'Hawaii'},
        {state_code: 'ID', state: 'Idaho'},
        {state_code: 'IL', state: 'Illinois'},
        {state_code: 'IN', state: 'Indiana'},
        {state_code: 'IA', state: 'Iowa'},
        {state_code: 'KS', state: 'Kansas'},
        {state_code: 'KY', state: 'Kentucky'},
        {state_code: 'LA', state: 'Louisiana'},
        {state_code: 'ME', state: 'Maine'},
        {state_code: 'MD', state: 'Maryland'},
        {state_code: 'MA', state: 'Massachusetts'},
        {state_code: 'MI', state: 'Michigan'},
        {state_code: 'MN', state: 'Minnesota'},
        {state_code: 'MS', state: 'Mississippi'},
        {state_code: 'MO', state: 'Missouri'},
        {state_code: 'MT', state: 'Montana'},
        {state_code: 'NE', state: 'Nebraska'},
        {state_code: 'NV', state: 'Nevada'},
        {state_code: 'NH', state: 'New Hampshire'},
        {state_code: 'NJ', state: 'New Jersey'},
        {state_code: 'NM', state: 'New Mexico'},
        {state_code: 'NY', state: 'New York'},
        {state_code: 'NC', state: 'North Carolina'},
        {state_code: 'ND', state: 'North Dakota'},
        {state_code: 'OH', state: 'Ohio'},
        {state_code: 'OK', state: 'Oklahoma'},
        {state_code: 'OR', state: 'Oregon'},
        {state_code: 'PA', state: 'Pennsylvania'},
        {state_code: 'RI', state: 'Rhode Island'},
        {state_code: 'SC', state: 'South Carolina'},
        {state_code: 'SD', state: 'South Dakota'},
        {state_code: 'TN', state: 'Tennessee'},
        {state_code: 'TX', state: 'Texas'},
        {state_code: 'UT', state: 'Utah'},
        {state_code: 'VT', state: 'Vermont'},
        {state_code: 'VA', state: 'Virginia'},
        {state_code: 'WA', state: 'Washington'},
        {state_code: 'WV', state: 'West Virginia'},
        {state_code: 'WI', state: 'Wisconsin'},
        {state_code: 'WY', state: 'Wyoming'},
      ],
      homeProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Home Number",
        maxLen: 30,
      },
      mobileProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Mobile Number",
        maxLen: 30,
      },
      signedWaiverData: {
        registration_id: '',
        waivers: []
      },
      currentDate: moment().format("M/D/YYYY H:mm"),
      waiverForm: {
        waivers_id: '',
        agree: '',
        initials: '',
      },
      currentRegContacts:[],
      waiversList: [],
      merchandiseDataMen: [],
      merchandiseDataWomen: [],
      merchandiseDataAccessories: [],
      cartProductData: [],
      loadWaiver: true,
      yearArr: [],
      couponErrors: {},
      propsMemberTypes: [],
      agree: false,
      membership_agree: "",
      age_criteria: "",
      member_type_id: "",
      member_type_fee: 0.0,
      member_type_text: "",
      steps: null,
      fields: [],
      skipToVerify: false,
      prop_confirmation: {},
      membershipAgeControl: [],
      familyMemberRenew:0,
      renewMerchandise: {},
      placeOrderDataGets: {
        "registration_id": '',
      },
      propSelectedMembership: null
    }

  },
  components: {
    Donation,
    DatePick,
    personalinformation,
    generalinfo,
    demographicinformation,
    contactinfo,
    familymembers,
    Merchandise,
    StripeElementCard
  },

  methods: {
    validateMembership() {
      this.errors = {};
      this.valid = false;
      const validMembershipId = validateMembershipSelected(this.form.membership_id);
      if(!validMembershipId.valid){
        this.valid = false;
      }else{
        this.valid = true;
      }
      this.errors.membership = validMembershipId.error;

      if(this.form.membership_agree === 0 && this.form.agree) {
        this.valid = false;
        this.errors.membership_agree = "Please acknowledge by checking the box";
      }
      return this.valid;
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    getCategories(){
        axios.get(this.basePath + "api/categories")
        .then(response => {
            this.categories = response.data.data;
        })
        .catch(error => {
            console.log("Error in getting categories: ", JSON.stringify(error));
        });
    },
    resetModal() {
      //this.verifyClubInfoData = {}
      //this.company = {}
      //this.member = {}
    },
    handlePersonalEditOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      if (this.validateVerifyPersonal()) {
        this.handleSubmit()
        this.$nextTick(() => {
          this.$bvModal.hide('modal-personal-edit')
        });
      }

    },
    handlePersonalDemographicEditOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      if (this.validateVerifyDemographic()) {
        // Trigger submit handler
        this.handleSubmit()
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-demographic-edit')
        });
      }
    },
    handlePersonalContactEditOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      if (this.validateVerifyContact()) {
        // Trigger submit handler
        this.handleSubmit()
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-contact-edit')
        });
      }
    },
    wizardNextstep: function () {
      if(this.wiz_step == 1){
        console.log('step:'+this.step);
        if(this.step == 1){
          if(!this.validateMembership()) return;
          if(this.skipToVerify){
            this.wiz_step3 = true;
            this.wiz_step1 = false;
            this.wiz_step2 = false;
            this.wiz_step = 3;
            // this.submitForm();
            this.verifyApiData();
          } else {
            this.step2 = true;
            this.step1 = false;
            this.step++;
          }
        } else if (this.step == 2){
          this.step3 = true;
          this.step2 = false;
          this.step++;
          this.waiverApi();
        } else if(this.step == 3&&this.subdomain == "shooting"&&this.membership.member_type_id==7){
          this.step7=true;
          this.step3=false;
          this.step = 6;
        } else if (this.step == 3){
          this.step4 = true;
          this.step3 = false;
          this.step++;
        }else if (this.step == 4){
          this.step5 = true;
          this.step4 = false;
          this.step++;
        }else if(this.step == 5){
          if(this.membership.id == 5) {
            this.step6 = true;
            this.step5 = false;
          } else {
            this.step7 = true;
            this.step5 = false;
          }
          this.step++;
        }
        else if(this.step == 6){
          if(this.membership.member_type_id == 3) {
            this.step7 = true;
            this.step6 = false;
            this.step5 = false;
          } else if(this.getSiteProps('general.merchandise')){
              this.step7 = false;
              this.wiz_step2 = true;
              this.wiz_step1 = false;
              this.wiz_step = 2;
            } else {
              this.step8 = this.getSiteProps('general.merchandise');
              this.step7 = false;
              this.wiz_step3 = true;
              this.wiz_step1 = false;
              this.wiz_step = 3;
            }

          this.step++;
        }
        else {
          // this.submitForm();
          if ( this.membership.member_type_id == 3 && this.getSiteProps('general.merchandise')){
            this.step7 = false;
            this.wiz_step2 = true;
            this.wiz_step1 = false;
            this.wiz_step = 2;
            this.step++;
          }
          else{
            this.step7 = false;
            this.wiz_step3 = true;
            this.wiz_step1 = false;
            this.wiz_step = 3;
            this.step++;
          }
        }
      }else if(this.wiz_step == 2){
        if(this.addedToCart == 1) {
          console.log('submit this step' + this.step);
          this.placeOrderCalculation();
          //this.placeOrderApi();

          document.getElementById('app').scrollIntoView();
        } else {
          this.calculateTotals();
        }
        this.wiz_step3 = true;
        this.wiz_step2 = false;
        this.wiz_step++;
      }else if(this.wiz_step == 3){
        this.wiz_step4 = true;
        this.wiz_step3 = false;
        this.wiz_step++;

        // if(this.form.member_type_id == 4) {
        if(this.ignoreWaiverMembership.length && this.ignoreWaiverMembership.includes(this.membership.id)) {
          this.wiz_step4 = false;
          this.wiz_step5 = true;
          this.wiz_step++;
          this.calculateTotals();

          this.paymentForm.billing_addressline1 = this.form.address1;
          this.paymentForm.billing_city = this.form.city;
          this.paymentForm.billing_state = this.form.state;
          this.paymentForm.billing_state_code = this.form.state_code;
          this.paymentForm.billing_country = this.form.country;
          this.paymentForm.billing_zip = this.form.zip;

          if(this.membership.id != 5) this.form.members.splice(1);
        } else {
          if(this.waiverData.length && this.waiverData[0].user_info[0].spouse_alert==1 && this.membership.id == 5) {
            this.$nextTick(() => {
              this.$bvModal.show('SpouseWaiverInfoModal');
            });
          }
        }
      } else if(this.wiz_step == 4){
        this.signedWaiverData.registration_id = this.regId;
        if(this.validateWaiver()) {
          if(this.currentWaiver == this.waiverData.length) {
            console.log('------------');
            this.addFilledWaiverData();
            // this.submitWaiverForm();
            this.wiz_step4 = false;
            this.wiz_step5 = true;
            this.wiz_step++;
            this.calculateTotals();

            this.paymentForm.billing_addressline1 = this.form.address1;
            this.paymentForm.billing_city = this.form.city;
            this.paymentForm.billing_state = this.form.state;
            this.paymentForm.billing_state_code = this.form.state_code;
            this.paymentForm.billing_country = this.form.country;
            this.paymentForm.billing_zip = this.form.zip;

          } else {
            console.log('-----444-------');
            this.addFilledWaiverData();
            this.waiverForm = {};
            console.log(this.currentWaiver);

            if(this.wiz_step < 4) {              
              this.currentWaiver = 0;
              for(var i=0;i<this.waiversList.length;i++) this.waiversList[i] = false;
              this.waiversList[this.currentWaiver] = true;              
            } else {              
              this.waiversList[this.currentWaiver-1] = false;
              this.waiversList[this.currentWaiver] = true;              
            }
            console.log("regstep="+this.wiz_step);
            console.log(this.waiversList);
            document.getElementById('app').scrollIntoView();
            return;

          }

        } else {
          console.log("regstep="+this.wiz_step);
          console.log('waiver un valid');
          // this.wiz_step++;
          //document.getElementById('app').scrollIntoView();
          return;
        }
      } else if(this.wiz_step == 5){
        if(this.totals.grand_total == 0.0 && (this.couponApplied.status == 1 || this.freeRegistration)){
          this.submitForm();
          return;
        } else {
          if(this.validatePayment()) {
            console.log("regstep="+this.wiz_step);
            console.log('payment valid');

            console.log("Stripe Form:", this.$refs.stripeForm);
            if(this.$refs.stripeForm != undefined){
              //Stripe Token generation
              this.showLoader=true;
              this.$refs.stripeForm.submit();
            } else {
              this.submitForm();
            }
            return;
          } else {
            console.log('payment un valid');
            document.getElementById('app').scrollIntoView();
            return;
          }
        }
      }
    },
    previousStep: function(){
      console.log('wiz step:'+ this.wiz_step)
      // if(this.wiz_step > 1 && this.wiz_step !=3){
      //   this.wiz_step--;

      // }
      console.log('after wiz step:'+ this.wiz_step)
      if(this.wiz_step == 1){
        this.wiz_step1 = true;
        this.step--;
        console.log('steps:'+this.step)
        if(this.step == 1){
          this.step1 = true;
          this.step2 = false;
        }else if(this.step == 2){
          this.step2 = true;
          this.step3 = false;
        }else if(this.step == 3){
          this.step3 = true;
          this.step4 = false;
        }else if(this.step == 4){
          this.step4 = true;
          this.step5 = false;
        }else if(this.step == 5&&this.subdomain == "shooting"&&this.membership.member_type_id==7){
          this.step7=false;
          this.step3=true;
          this.step = 3;
        }
        else if(this.step == 5) {
          if(this.membership.id == 5) {
            this.step6 = false;
            this.step5 = true;
          } else {
            this.step7 = false;
            this.step5 = true;
          }
        } else if(this.step == 6) {
          if(this.membership.id == 5) {
            this.step7 = false;
            this.step6=true;
          } else {
            this.wiz_step2 = false;
            this.step7 = true;
          }
        }else{
          this.previousStep();
        }
      }else if(this.wiz_step == 2){
        //this.wiz_step2 = true;
        if(this.skipToVerify){
          this.step = 1;
          this.step1 = true;
          this.step7 = false;
        } else {
          this.step--;
          this.step7 = true;
        }
        this.wiz_step = 1;
        this.wiz_step1 = true;
        this.wiz_step2 = false;
      }else if(this.wiz_step == 3){
        if(this.wiz_step4 == true){
          console.log('current waiver:'+ this.currentWaiver)
          // if(this.currentWaiver == 0) {
          //   this.wiz_step3 = true;
          //   this.wiz_step4 = false;
          // }else{
          //   this.waiversList[this.currentWaiver-1] = true;
          //   this.waiversList[this.currentWaiver] = false;
          //   this.currentWaiver = this.currentWaiver - 1;
          //   console.log(this.waiversList);
          // }
          this.wiz_step3 = true;
          this.wiz_step4 = false;
        } else if(this.wiz_step5) {
          this.wiz_step5 = false;
          this.wiz_step4 = false;
          this.wiz_step3 = true;
          this.currentWaiver--;
        } else {
          // alert(this.step+"||"+this.wiz_step);
          if(this.getSiteProps('general.merchandise')) {
            this.wiz_step = 2;
            this.wiz_step2 = true;
            this.wiz_step3 = false;
          }else{
            if(this.skipToVerify){
              this.step = 1;
              this.step1 = true;
              this.step7 = false;
            } else {
              this.step--;
              this.step7 = true;
            }
            this.wiz_step = 1;
            this.wiz_step1 = true;
            this.wiz_step3 = false;
          }

        }
      }else if(this.wiz_step == 4){
        if(this.currentWaiver > 0){
          this.currentWaiver = this.currentWaiver - 1;
          for(var i=0;i<this.waiversList.length;i++) this.waiversList[i] = false;
          this.waiversList[this.currentWaiver] = true;
          this.wiz_step4 = true;
          if(this.waiverData.length && this.waiverData[0].user_info[0].spouse_alert==1 && this.membership.id == 5) {
            this.$nextTick(() => {
              this.$bvModal.show('SpouseWaiverInfoModal');
            });
          }
        } else {
          this.wiz_step--;
          this.wiz_step3 = true;
          this.wiz_step4 = false;
        }
      } else if(this.wiz_step == 5){
        this.currentWaiver--;
        this.wiz_step--;
        this.wiz_step4 = true;
        this.wiz_step5 = false;

        // if(this.form.member_type_id == 4){
        if(this.ignoreWaiverMembership.length && this.ignoreWaiverMembership.includes(this.membership.id)) {
          this.wiz_step--;
          this.wiz_step3 = true;
          this.wiz_step4 = false;
        }
      }


      document.getElementById('app').scrollIntoView();
    },
    recordDonationAmountData(donationData) {
      this.form.donation_amount = donationData.donation_amount != '' ? parseFloat(donationData.donation_amount).toFixed(2) : 0;
      this.form.donation_id = donationData.donation_discipline != '' ? donationData.donation_discipline : '';
      this.donated.amount = this.form.donation_amount;
      this.donated.type = donationData.donation_discipline;
      console.log('donation steps='+this.step);
      this.wizardNextstep();
      console.log('donation steps='+this.step);
    },
    getActiveRegistration() {
      axios.get(this.basePath + 'api/member/active_registration?renew=1', {
        headers:this.memberHeaders
      })
          .then((res) => {
            let active = res.data.data;
            if(this.subdomain == 'alliancefastpitch' || [7,9,10].includes(active.membership_status)) this.$router.push({ name: "memberDashboard" });
            this.paymentForm.old_registration_id = active.id;
            // this.form = active;
            for (let prop in active) {

              if (Object.prototype.hasOwnProperty.call(this.form, prop)) {
                if(prop == 'members'){
                  for (let prp in active[prop]) {
                    if (Object.prototype.hasOwnProperty.call(this.form.members[0], prp)) {
                      this.form.members[0][prp] = active[prop][prp];
                    }
                  }
                } else if(prop == "affiliate_club") {
                  for (let prp in active[prop]) {
                    if (Object.prototype.hasOwnProperty.call(this.form.affiliate_club, prp)) {
                      this.form.affiliate_club[prp] = active[prop][prp];
                    }
                  }
                } else {
                  this.form[prop] = active[prop];
                }
              }
              if(prop == 'families' ){
                var idx = 0;
                for (let fmly in active[prop]) {
                 idx = idx + 1;
                  this.form.members[idx] = active[prop][fmly];
                  this.form.members[idx]['phone_1'] = active[prop][fmly]['phone1'];
                  this.form.members[idx]['phone_2'] = active[prop][fmly]['phone2'];
                }
              }
              if (prop == 'safe_sport') {
                for (let safe in active[prop]) {
                  if (Object.prototype.hasOwnProperty.call(this.form, safe)) {
                    this.form[safe] = active[prop][safe];
                  }
                }
              }
            }
            if(active.relation > 0){
              this.familyMemberRenew = 3;
              this.form.members[0].relation = 0;
            }

            this.form.current_member_id = active.members.member_id;
            this.form.member_type_id = active.member_type_id;
            if(active.membership_cost > 0) {
              this.form.membership_id = active.membership_type_id;
              this.form.member_type_text = active.membership_type;
              this.form.member_type_fee = active.membership_cost;
            }
            this.form.retype_email = active.email;
            this.form.diversity_id = active.members.diversity_id;
            this.form.ethnicity = active.members.ethnicity;
            this.form.disability = active.members.disability;
            this.form.veteran_status = active.members.veteran_status;
            this.membership.fee = active.membership_cost;
            this.membership.type = active.membership_type;
            this.membership.id = active.membership_type_id;
            if(this.currentPath == 'upgrade' || active.member_type == 'Ambassador'){
              let upgradeType = active.membership_type.toLowerCase().trim()+'_member_type';
              this.propsMemberTypes = this.getSiteProps(upgradeType.replace(" ", "_"));
              let accordionId = active.membership_type_id === 10 ? 6 : 1;
              setTimeout(() => {
                this.$root.$emit('bv::toggle::collapse', 'accordion-' + accordionId);
              }, 1500);
            }
            if(active.member_type == 'Ambassador' && this.currentPath != 'upgrade'){
              // this.wizardNextstep();
              setTimeout(() => {
                this.$root.$emit('bv::toggle::collapse', 'accordion-' + active.member_type_id);
              }, 1500);
            }

            if(active.member_type_id == 6)
              this.$root.$emit('bv::toggle::collapse', 'accordion-2')
            else if(active.member_type_id == 5)
              this.$root.$emit('bv::toggle::collapse', 'accordion-1')
            else
              this.$root.$emit('bv::toggle::collapse', 'accordion-' + active.member_type_id)

            this.member_type_id = active.member_type_id;
            this.mountPropData();

            this.regId = res.data.data.id;
            // this.waiverApi();

            this.ageValidation();

            if(active.member_type_id == 3 && active.relation > 0)
            {
              this.$root.$emit('bv::toggle::collapse', 'accordion-1')
              this.form.membership_id = '';
            }

            this.propMembershipTypes.forEach(row => {
              if(row.memberships !== undefined && Array.isArray(row.memberships)){
                row.memberships.forEach(membership => {
                  if(membership.id === active.membership_type_id){
                    this.propSelectedMembership = membership;
                  }
                });
              }
            });
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.button.nextButton = 'Next Step';
            return false;
          }).finally(() => {


      });
    },
    getCurrentRegistration() {
      axios.get(this.basePath + 'api/member/get_active_registration', {
        headers:this.memberHeaders
      }).then((res) => {
        this.regId = res.data.data.id;
        this.verifyData = res.data.data;
        this.verifyData.membership_id = this.verifyData.membership_type_id;
        this.verifyData.retype_email = this.verifyData.email;
        //this.verifyData.diversity_id = 1;
        this.verifyData.members.relation = 0;
        this.verifyData.members.reg_member_id = this.verifyData.members.id;
        this.verifyData.diversity_id = this.verifyData.members.diversity_id;
        this.verifyData.ethnicity = this.verifyData.members.ethnicity;
        this.verifyData.disability = this.verifyData.members.disability;
        this.verifyData.veteran_status = this.verifyData.members.veteran_status;

        //Payment form
        this.paymentForm.billing_addressline1 = this.verifyData.address1;
        this.paymentForm.billing_city = this.verifyData.city;
        this.paymentForm.billing_state = this.verifyData.state;
        this.paymentForm.billing_state_code = this.verifyData.state_code;
        this.paymentForm.billing_country = this.verifyData.country;
        this.paymentForm.billing_zip = this.verifyData.zip;

        let active = res.data.data;

        // this.form = active;
        for (let prop in active) {
          if (Object.prototype.hasOwnProperty.call(this.form, prop)) {
            if (prop != 'members') {
              this.form[prop] = active[prop]
            } else if (prop == 'members') {
              for (let prp in active[prop]) {
                if (Object.prototype.hasOwnProperty.call(this.form.members[0], prp)) {
                  this.form.members[0][prp] = active[prop][prp];
                }

              }
            }

          }
          if (prop == 'safe_sport') {
            for (let safe in active[prop]) {
              if (Object.prototype.hasOwnProperty.call(this.form, safe)) {
                this.form[safe] = active[prop][safe];
              }
            }
          }
        }
        this.form.member_type_id = active.membership_type_id;
        this.form.membership_id = active.membership_type_id;
        this.form.retype_email = active.email;
        this.form.printed_magazine = active.printed_magazine;
        this.form.diversity_id = active.members.diversity_id;
        this.form.ethnicity = active.members.ethnicity;
        this.form.disability = active.members.disability;
        this.form.veteran_status = active.members.veteran_status;
        this.membership.fee = active.membership_cost;
        this.membership.type = active.membership_type;
        this.membership.id = active.membership_type_id;

        this.waiverApi();
        //this.merchandiseDataApi();
        console.log(active.member_type_id)
        this.$root.$emit('bv::toggle::collapse', 'accordion-' + active.member_type_id)
        return res;
      })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {
      });
    },
    verifyApiData() {
      console.log("Verify!");
      axios.get(this.basePath + 'api/verify/' + this.regId)
          .then((res) => {
            //Perform Success Action
            //alert('success')
            console.log('verify update success');
            this.verifyData = res.data.data;
            this.verifyData.membership_id = this.verifyData.membership_type_id;
            this.verifyData.member_type_id = this.verifyData.membership_type_id;

            this.verifyData.retype_email = this.verifyData.email;
            //this.verifyData.diversity_id = 1;
            this.verifyData.members.relation = 0;
            this.verifyData.members.reg_member_id = this.verifyData.members.id;
            this.verifyData.diversity_id = this.verifyData.members.diversity_id;
            this.verifyData.ethnicity = this.verifyData.members.ethnicity;
            this.verifyData.disability = this.verifyData.members.disability;
            this.verifyData.veteran_status = this.verifyData.members.veteran_status;

            //Payment form
            this.paymentForm.billing_addressline1 = this.verifyData.address1;
            this.paymentForm.billing_city = this.verifyData.city;
            this.paymentForm.billing_state = this.verifyData.state;
            this.paymentForm.billing_state_code = this.verifyData.state_code;
            this.paymentForm.billing_country = this.verifyData.country;
            this.paymentForm.billing_zip = this.verifyData.zip;

            this.waiverApi();
            //this.merchandiseDataApi();
            //console.log(this.verifyData);
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {
      });
    },
    submitForm() {
      this.showLoader=true;
      console.log("Form submitted!",this.newRegId);
      if(this.newRegId !=''){
        return this.paymentApi();
      }
      this.renewMerchandise.oldRegId = this.regId;
      // this.button.nextButton = 'Registration is in progress...';
      // var slug = 'api/update/member_reg/'+this.regId;
      var slug = 'api/member_registration';
      if(this.subdomain == 'shooting'&&[11,12,13].includes(this.membership.id))this.form.diversity_id = this.form.members[0].diversity_id
      axios.post(this.basePath+slug, this.form)
          .then((res) => {
            if(res.data.status == 'success') {
              // this.button.nextButton = 'Next';
              this.regId = res.data.data.id;
              this.newRegId = res.data.data.id;
              this.paymentForm.registration_id = this.regId;
              this.renewMerchandise.regId = this.newRegId;
              // this.step7 = false;
              // this.wiz_step3 = true;
              // this.wiz_step1 = false;
              // this.wiz_step = 3;
              // this.verifyApiData();
              if(this.placeOrderDataGets.registration_id != undefined && this.placeOrderDataGets.registration_id != '') {
                this.placeOrderApi();
              }


              this.signedWaiverData.registration_id = this.regId;
              this.submitWaiverForm();
       
              this.paymentApi();

              return res;
            } else {
              // this.button.nextButton = 'Next';
              return res;
            }
          })
          .catch((error) => {
            console.log('Error:'+ error);
            this.showLoader=false;
            //this.step--;
            // this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
      });
    },
      paymentApi() {
      this.showLoader=true;
      console.log("Payment submitted!");
      this.button.nextStepButton = 'Payment is in progress...';
      this.paymentForm.cardNumber = this.paymentForm.cardNumber.replace(/\s/g,'');
      this.paymentForm.name_on_card = this.paymentForm.billing_firstname;
      axios.post(this.basePath+'api/member/paynow/'+ this.regId, this.paymentForm, { headers: this.memberHeaders })
          .then((res) => {
            if(res.data.status == 'success') {
              //Perform Success Action
              //alert('success')
              this.showLoader=false;
              console.log('payment success');
              this.button.nextStepButton = 'Next Step';
              console.log(res.data.message);
              this.paymentData = res.data.message;
              this.registration_message_flag = res.data.data.registration_message_flag;
              this.registration_message_text = res.data.data.registration_message_text;
              //console.log(this.verifyData);
              console.log('Finally');
              console.log("f="+ this.wiz_step);
              this.wiz_step++;
              console.log("s="+ this.wiz_step);
              this.wiz_step5 = false;
              this.wiz_step6 = true;
              this.verifyApiData();
              this.loadActiveMembersInfo();
              document.getElementById('app').scrollIntoView();
              return res;
            } else {
              this.showLoader=false;
              this.errors.payment_agree = res.data.errors;
              this.button.nextStepButton = 'Next Step';
            }
          })
          .catch((error) => {
            this.showLoader=false;
            console.log('Error:'+ error);
            this.errors.payment_agree = error.errors;
            //this.step--;
            this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {

      });
    },
    loadActiveMembersInfo(){
        axios.get(this.basePath+"api/member/get_active_info",{ headers:this.memberHeaders })
            .then((response)=>{
                const resp  = response.data.data;
                let activeMemberInfoData = resp;
                localStorage.setItem('member',JSON.stringify(activeMemberInfoData));
            }).catch((error) =>{
            console.log(error);
        });
    },
    merchandiseDataMenApi() {
      console.log("Merchandise!");
      axios.get(this.basePath + 'api/merchandise_product_list?category_id=' + 1)
          .then((res) => {
            //Perform Success Action
            //alert('success')
            console.log('merchandise success');
            this.merchandiseDataMen = res.data.data;
            console.log('===========');
            console.log(this.merchandiseDataMen[0].images[0].url);
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {


      });
    },
    merchandiseDataWomenApi() {
      console.log("Merchandise!");
      axios.get(this.basePath + 'api/merchandise_product_list?category_id=' + 2)
          .then((res) => {
            //Perform Success Action
            //alert('success')
            console.log('merchandise success');
            this.merchandiseDataWomen = res.data.data;
            //console.log(this.merchandiseData[0]);
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {


      });
    },
    merchandiseDataAccessoriesApi() {
      console.log("Merchandise!");
      axios.get(this.basePath + 'api/merchandise_product_list?category_id=' + 3)
          .then((res) => {
            //Perform Success Action
            //alert('success')
            console.log('merchandise success');
            this.merchandiseDataAccessories = res.data.data;
            //console.log(this.merchandiseData[0]);
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {


      });
    },
    placeOrderApi() {
      console.log("Place order submitted!");
      console.log('----------------------');
      this.button.nextButton = 'Place order is in progress...';
      this.orderPlaceData.registration_id = this.regId;
      this.orderPlaceData.isRenew = true;
      this.orderPlaceData.old_regiatration_id = this.placeOrderDataGets.registration_id;
      axios.post(this.basePath+'api/merchandise_place_order', this.orderPlaceData)
          .then((res) => {
            console.log('----------'+res.data.status);
            if(res.data.status == 'success') {
              //Perform Success Action
              //Perform Success Action
              //alert('success')
              console.log('placeOrder success');

              //this.step++;
              this.button.nextButton = 'Next';
              this.placeOrderData = res.data.data;
              this.cartProductCount = this.placeOrderData.cart_product_count;
              this.totals.merchandise_total = this.placeOrderData.sub_total;
              this.totals.shipping_total = this.placeOrderData.shipping_cost;
              this.renewMerchandise.orderId = this.placeOrderData.id;
              this.calculateTotals();
              console.log('myloog');

              this.submitMerchandiseForm();
              //console.log(this.verifyData);
              return res;
            } else if(res.data.status == 'error') {
              this.button.nextButton = 'Next';
              this.placeOrderData = [];
              this.cartProductCount = 0;
              this.totals.merchandise_total = 0.00;
              this.totals.shipping_total = 0.00;
              this.totals.shipping_first_item = 0.00;
              this.totals.shipping_additional_item = 0.00;
              this.calculateTotals();
              return res;
            } else {
              this.button.nextButton = 'Next';
              this.placeOrderData = [];
              this.cartProductCount = 0;
              this.totals.merchandise_total = 0.00;
              this.totals.shipping_total = 0.00;
              this.totals.shipping_first_item = 0.00;
              this.totals.shipping_additional_item = 0.00;
              this.calculateTotals();
              console.log('placeOrder success'+res.data.status);
              return res;
            }
          })
          .catch((error) => {
            console.log('Error:'+ error);
            //this.step--;
            this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
      });
    },
    removeFromCart(cart_id) {
      let cartData = {};
      cartData.cart_id = cart_id;
      cartData.registration_id = this.regId;
      console.log('---------------');
      console.log(cartData);
      axios.post(this.basePath+'api/merchandise_remove_from_cart', cartData)
          .then((res) => {
            if(res.data.status == 'success') {
              this.cartProductData = res.data.data;
              console.log('cart--length--' + this.cartProductData.length);
              console.log('addedToCart----' + this.addedToCart);
              this.placeOrderCalculation();
            }
            return res;
          })
          .catch((error) => {
            console.log('Error:'+ error);
            //this.step--;
            //this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {
      });
    },
    recordAddedToCart(status) {
      this.addedToCart = status;
      this.wizardNextstep();
    },
    calculateTotals() {
      console.log('calculate total');
      let membershipFee = this.membership.fee;
      console.log(membershipFee);
      if(this.couponApplied.status == 1) {
        membershipFee = this.membership.fee - this.couponApplied.amount;
        membershipFee = membershipFee < 0 ? 0 : membershipFee;
      }
      // console.log('--calculate total--');
      // console.log(membershipFee);
      // console.log(this.donated.amount);
      // console.log(this.donated.amount);
      // console.log(this.totals.merchandise_total);
      // console.log(this.totals.shipping_total);
      this.totals.sub_total = parseFloat(this.removeCommaFromNumber(membershipFee) + this.removeCommaFromNumber(this.donated.amount) + this.removeCommaFromNumber(this.totals.merchandise_total) + this.removeCommaFromNumber(this.totals.shipping_total)).toFixed(2);
      
      console.log('---------');
      console.log(this.totals);
      this.processingFee = parseFloat(parseFloat(this.removeCommaFromNumber(this.totals.sub_total))/100 * this.removeCommaFromNumber(this.processingFeePercent)).toFixed(2);
      this.totals.grand_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total)+this.removeCommaFromNumber(this.processingFee)).toFixed(2);
      console.log('---------');
      console.log(this.processingFee);
      console.log(this.totals);

      this.totals.sub_total = this.totals.sub_total < 0 ? 0 : this.totals.sub_total;
      this.totals.grand_total = this.totals.grand_total < 0 ? 0 : this.totals.grand_total;

      if(this.totals.grand_total == 0.0){
          this.paymentForm.visible = false;
          this.paymentForm.auto_renew = 0;
          this.freeRegistration = true;
          this.paymentForm.zero_amount = true;
          this.paymentForm.coupon_id = this.form.coupon_id;
      } else {
          this.paymentForm.visible = true;
          this.paymentForm.auto_renew = 1;
          this.paymentForm.zero_amount = false;
        this.paymentForm.coupon_id = "";
      }
    },
    getMemberType() {

      if(this.currentPath == 'upgrade'){
        this.memberTypeUrl = this.basePath + "api/getUpgradeMemberType";
      }
      axios.get(this.memberTypeUrl, {
        headers:this.memberHeaders
      })
          .then(response => {
            this.memberTypes = response.data.data;
            console.log(this.memberTypes);
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    handleSubmit() {
      this.verifyData.members = [this.verifyData.members];

      this.verifyUpdateApi();
      //this.$emit('insurance-data', this.form);
    },
    verifyUpdateApi() {
      console.log("Verify update!");
      axios.put(this.basePath + 'api/verify/' + this.regId, this.verifyEditData)
          .then((res) => {
            if (res.data.status == 'success') {

              console.log('verify success');
              this.verifyData = res.data.data;
              this.verifyData.membership_id = this.verifyData.membership_type_id;
              this.verifyData.retype_email = this.verifyData.email;
              this.verifyApiData();
              return res;
            } else {
              return res;
            }
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {

      });
    },
    waiverApi() {
      axios.get(this.basePath + 'api/waivers/' + this.regId+'/'+1+'/'+this.form.membership_id+'?upgrade='+(this.currentPath == 'upgrade'))
          .then((res) => {
            this.waiversList[0] = true;
            if(this.membership.id == 2) {
              this.waiverData = [];
              res.data.data.forEach((value, index) => {
                let tempWaiver = value;
                if (value.type == 'Minor') {
                  tempWaiver.user_info[0].member_initials = '';
                  tempWaiver.user_info[0].signed_member = '';
                } else if (index != 0 && value.type == 'SafeSport' && res.data.data[0].type == 'Minor') {
                  tempWaiver.user_info[0].member_initials = '';
                  tempWaiver.user_info[0].signed_member = '';
                }
                this.waiverData.push(tempWaiver);
              });
            } else {
              this.waiverData = res.data.data;
              this.waiverData.forEach((value, index) => {
                this.waiverData[index].user_info[0].member_name = this.form.members[0].first_name+" "+this.form.members[0].last_name;
                this.waiverData[index].user_info[0].signed_member = this.form.members[0].first_name+" "+this.form.members[0].last_name;
              });
            }
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {
      });
    },
    removeDonation() {
      this.donated = {
        type: '',
        amount: 0.00
      };
      this.form.donation_id = null;
      this.form.donation_amount = 0;
      this.calculateTotals();
    },
    getAge(birth_date) {
      let birth_date_arr = birth_date.split('/');
      console.log(birth_date_arr);
      let today_date = new Date();
      let today_year = today_date.getFullYear();
      let today_month = today_date.getMonth();
      let today_day = today_date.getDate();
      let age = today_year - birth_date_arr[2];

      if (today_month < (birth_date_arr[0] - 1)) {
        age--;
      }
      if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1])) {
        age--;
      }
      return age;
    },
    ageGroup(birth_date) {
      console.log(birth_date);
      let age = this.getAge(birth_date);
      console.log(age);
      if (age > 17) {
        return 'Minor';
      } else if (age <= 17) {
        return 'Adult';
      }
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isAlphabets: function (evt) {

      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      console.log('--' + charCode);
      if (charCode > 64 && charCode < 122) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    validateVerifyPersonal() {
      this.errors = {}

      const validFirstName = validateName(this.verifyData.members.first_name);
      this.errors.first_name = validFirstName.error;

      const validLastName = validateName(this.verifyData.members.last_name);
      this.errors.last_name = validLastName.error;

      const validDob = validateDob(this.verifyData.members.dob);
      this.errors.dob = validDob.error;

      const validGender = validateGender(this.verifyData.members.gender);
      this.errors.gender = validGender.error;

      const validSelect = validateSelect(this.verifyData.members.citizenship);
      this.errors.citizenship = validSelect.error;

      if (validFirstName.valid && validLastName.valid && validGender.valid && validDob.valid && validSelect.valid) {
        let dob = this.getAge(this.verifyData.members.dob);
        console.log(this.membership.id);
        console.log('dob=' + dob);
        if (this.membership.id == 1 && dob < 25) {
          console.log('dob1=' + dob);
          this.valid = false;
          this.errors.dob = 'Individual Active user age should be age 25 or older. Go back and change membership type to U25 Active membership.';
        } else if (this.membership.id == 2 && dob >= 25) {
          console.log('dob1=' + dob);
          this.valid = false;
          this.errors.dob = 'Individual Under 25 Active user age should be less than 25';
        } else if (this.membership.id == 5 && dob < 25) {
          console.log('dob3=' + dob);
          this.valid = false;
          this.errors.dob = 'Family Head of house age should be greater than 25';
        } else {
          console.log('dob2=' + dob);
          this.errors.dob = '';
          this.valid = true;
        }

        if (!this.validateDate(this.form.dob)) {
          this.valid = false;
          this.errors.dob = 'Invalid Date';
        }
      }

      return this.valid;
    },
    validateVerifyDemographic() {
      this.errors = {}

      const validDiversity = validateRadio(this.verifyData.diversity_id);
      this.errors.diversity_id = validDiversity.error;

      const validEthnicity = validateRadio(this.verifyData.ethnicity);
      this.errors.ethnicity = validEthnicity.error;

      const validDisability = validateRadio(this.verifyData.disability);
      this.errors.disability = validDisability.error;

      const validVeteranStatus = validateRadio(this.verifyData.veteran_status);
      this.errors.veteran_status = validVeteranStatus.error;

      if (validDiversity.valid && validEthnicity.valid && validDisability.valid && validVeteranStatus.valid) {
        this.valid = true;
      }

      return this.valid;
    },
    getDiversity() {
      axios.get(this.basePath + "api/getDiversity")
          .then(response => {
            this.diversities = response.data.data;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    getDiscipline() {
      axios.get(this.basePath + "api/getDisciplines")
          .then(response => {
            this.disciplines = response.data.data;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    getGroup() {
      axios.get(this.basePath + 'api/getGroup')
          .then(response => {
            this.groups = response.data.data;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    validateVerifyContact() {
      this.errors = {}
      this.valid = false;
      console.log('----------');
      console.log(this.verifyData);
      const validEmail = validateEmail(this.verifyData.email);
      this.errors.email = validEmail.error;

      const validRetypeEmail = validateEmail(this.verifyData.retype_email);
      this.errors.retype_email = validRetypeEmail.error;

      const validAddress1 = validateName(this.verifyData.address1);
      this.errors.address1 = validAddress1.error;

      const validAddress2 = validateName(this.verifyData.address2);
      this.errors.address2 = validAddress2.error;

      const validCountry = validateSelect(this.verifyData.country);
      this.errors.country = validCountry.error;

      const validStateCode = validateSelect(this.verifyData.state_code);
      const validState = validateStateName(this.verifyData.state);

      if (validCountry.valid && this.verifyData.country != 'USA') {
        this.errors.state = validState.error;
      } else {
        this.errors.state_code = validStateCode.error;
      }

      const validCity = validateName(this.verifyData.city);
      this.errors.city = validCity.error;

      const validZip = validateName(this.verifyData.zip);
      this.errors.zip = validZip.error;

      const validPhone1 = validateName(this.verifyData.phone_1);
      this.errors.phone_1 = validPhone1.error;

      const validPhone2 = validateName(this.verifyData.phone_2);
      this.errors.phone_2 = validPhone2.error;

      if (validEmail.valid && validRetypeEmail.valid && validAddress1.valid && validAddress2.valid && validCountry.valid
          && validCity.valid && validZip.valid && validPhone1.valid && validPhone2.valid) {
        this.valid = true;
        if (this.verifyData.email != this.verifyData.retype_email) {
          this.errors.retype_email = 'The email id does not match';
          this.valid = false;
        }
        if (this.verifyData.country == 'USA') {
          this.verifyData.state = '';
          if (!validStateCode.valid) {
            this.valid = false;
          }
        } else if (!validState.valid) {
          this.verifyData.state_code = '';
          this.valid = false;
        }
      }

      return this.valid;
    },
    validateWaiver() {
      if(this.membership.id!=5)
      {
        let minorindx = 2;
        this.waiverData.forEach((value, index) => {                       
          if (value.type == 'Minor' && value.user_info.length==0) {
            minorindx = index;
          }        
        });
        this.waiverData.splice(minorindx,1);
        this.form.members.splice(1);
      }
      console.log('this waiver'+this.currentWaiver);
      console.log(this.waiverDataAgree);
      console.log(this.waiverData);
      this.errors = {}
      this.valid = false;
      let currentWaiverLength = this.waiverData[this.currentWaiver].user_info.length;
      let validWaiverAgree = {};
      let validWaiverSign = {};
      let validWaiverSignedMember = {};
      console.log('-----------------------'+currentWaiverLength);
      for(let i = 0; i < currentWaiverLength; i++) {
        console.log('-----------------------');
        console.log(this.waiverDataAgree[this.currentWaiver]);
        console.log(this.waiverData[this.currentWaiver]);
        validWaiverAgree = validateWaiverCheckbox(this.waiverDataAgree[this.currentWaiver].agree[i]);
        validWaiverSign = validateWaiverInitials(this.waiverData[this.currentWaiver].user_info[i].member_initials);
        //console.log('this waiver validWaiverAgree' + this.waiverDataAgree[this.currentWaiver].agree[i]);
        //console.log('this waiver validWaiverSign' + this.waiverData[this.currentWaiver].user_info[i].member_initials);
        if(this.membership.member_type_id != 4) {
          validWaiverSignedMember = validateSignedUserName(this.waiverData[this.currentWaiver].user_info[i].signed_member);
          this.waiverErrors[this.currentWaiver].signed_member[i] = validWaiverSignedMember.error;
        } else {
          validWaiverSignedMember.valid = true;
        }
        this.waiverErrors[this.currentWaiver].agree[i] = validWaiverAgree.error;
        this.waiverErrors[this.currentWaiver].initials[i] = validWaiverSign.error;
      }

      if (validWaiverAgree.valid && validWaiverSign.valid && validWaiverSignedMember.valid) {
        console.log('pass');
        this.valid = true;
        if(this.membership.member_type_id == 4)
        {
          //const validWaiverSignedUser = validateSignedUserName(this.waiverData[this.currentWaiver].user_info.signed_user);
          const validClubWaiverFirstName = validateSignedUserName(this.clubSubmittedBy.first_name);
          const validClubWaiverLastName = validateSignedUserName(this.clubSubmittedBy.last_name);
          const validClubWaiverPosition = validateSignedUserName(this.clubSubmittedBy.position);
          const validClubWaiverEmail = validateSignedUserName(this.clubSubmittedBy.email);
          //this.waiverErrors.signed_user = validWaiverSignedUser.error;
          this.waiverClubErrors.club_submitted_first_name = validClubWaiverFirstName.error;
          this.waiverClubErrors.club_submitted_last_name = validClubWaiverLastName.error;
          this.waiverClubErrors.club_submitted_position = validClubWaiverPosition.error;
          this.waiverClubErrors.club_submitted_email = validClubWaiverEmail.error;
          if(!validClubWaiverFirstName.valid || !validClubWaiverLastName.valid || !validClubWaiverPosition.valid || !validClubWaiverEmail.valid) {
            this.valid = false;
            return this.valid;
          }
        }
        console.log(this.currentWaiver+' === '+this.waiverData.length);
        if(this.currentWaiver < this.waiverData.length) {
          console.log(this.currentWaiver+' < '+this.waiverData.length);
          this.currentWaiver = this.currentWaiver + 1;
          console.log(this.currentWaiver+' < '+this.waiverData.length);
        }
      }
      return this.valid;
    },
    submitWaiverForm() {
      console.log("Waiver Form submitted!");
      //this.button.nextStepButton = 'Registration is in progress...';
      axios.post(this.basePath + 'api/waivers/' + this.regId, this.signedWaiverData)
          .then((res) => {
            if (res.data.status == 'success') {
              //Perform Success Action
              //alert('success')
              //console.log('success');
              //console.log('success');
              //this.step++;
              //this.button.nextStepButton = 'Next Step';
              //this.regId = res.data.data.id;
              //this.paymentForm.registration_id = this.regId;
              //console.log(this.verifyData);
            }
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
        //this.verifyApiData();

      });
    },
    validatePayment() {
      this.errors = {}
      this.valid = false;

      const validPaymentFirstName = validateName(this.paymentForm.billing_firstname, "Card Name");
      this.errors.billing_firstname = validPaymentFirstName.error;

      let validCardNumber = { valid: true, error: null };
      let validExpirationYear = { valid: true, error: null };
      let validExpirationMonth = { valid: true, error: null };
      let validCvv = { valid: true, error: null };
      if(this.getSiteProps('general.paymentGateway') == 'authorizenet'){
        validCardNumber = validateCardNumber(this.paymentForm.cardNumber, "Card Number");
        this.errors.cardNumber = validCardNumber.error;

        validExpirationYear = validateSelect(this.paymentForm.expiration_year, "Year" );
        this.errors.expiration_year = validExpirationYear.error;

        validExpirationMonth = validateSelect(this.paymentForm.expiration_month, "Month");
        this.errors.expiration_month = validExpirationMonth.error;

        validCvv = validateCvv(this.paymentForm.cvv, "CVV/CVC");
        this.errors.cvv = validCvv.error;
      }

      const validAddress1 = validateName(this.paymentForm.billing_addressline1, "Mailing Address");
      this.errors.billing_addressline1 = validAddress1.error;

      const validCountry = validateSelect(this.paymentForm.billing_country);
      this.errors.billing_country = validCountry.error;

      const validStateCodeSelect = validateSelect(this.paymentForm.billing_state_code,"State");
      const validStateCodeInput = validateStateName(this.paymentForm.billing_state);

      if(validCountry.valid && this.paymentForm.billing_country == 'USA') {
        this.errors.billing_state_code = validStateCodeSelect.error;
      } else if(this.paymentForm.billing_country == 'Canada') {
        this.errors.billing_state = validStateCodeInput.error;
      }

      const validCity = validateName(this.paymentForm.billing_city, "City");
      this.errors.billing_city = validCity.error;

      // const validZip = validateName();
      // this.errors.billing_zip = validZip.error;

      const  validZip = validateZip(this.paymentForm.billing_zip, this.paymentForm.billing_country);
      this.errors.billing_zip = validZip.error;

      const validPaymentSign = validateWaiverInitials(this.paymentForm.sign);
      this.errors.payment_sign = validPaymentSign.error;

      const validPaymentAgree = validateWaiverCheckbox(this.paymentForm.agree);
      this.errors.payment_agree = validPaymentAgree.error;

      if (validPaymentFirstName.valid && validCardNumber.valid
          && validExpirationYear.valid && validExpirationMonth.valid && validCvv.valid && validAddress1.valid && validCountry.valid
          && validCity.valid && validZip.valid && validPaymentAgree.valid && validPaymentSign.valid && this.stripeError == "") {
        this.valid = true;
        if(this.paymentForm.billing_country == 'USA') {
          this.paymentForm.billing_state = '';
          if(!validStateCodeSelect.valid) {
            this.valid = false;
          }
        } else if(!validStateCodeInput.valid && this.paymentForm.billing_country == 'Canada') {
          this.paymentForm.billing_state_code = '';
          this.valid = false;
        }
      }

      return this.valid;
    },
    saveDonation() {
      if (this.donationData.donation_amount > 0) {
        this.button.nextStepButton = 'Save Donation...';
        axios.post(this.basePath + 'api/saveDonation/' + this.regId, this.donationData)
            .then((res) => {
              this.button.nextStepButton = 'Next Step';
            })
            .catch((error) => {
              this.button.nextStepButton = 'Next Step';
              return false;
            }).finally(() => {

          this.wiz_step++;
          document.getElementById('app').scrollIntoView();
        });
      } else {
        this.wiz_step++;
        document.getElementById('app').scrollIntoView();
      }
    },
    updateMemberType() {
      axios.put(this.basePath + 'api/updateMemberType/' + this.regId, this.form)
          .then((res) => {
            if (res.data.status == 'success') {
              console.log('verify success');
              this.verifyData = res.data.data;
              this.verifyData.membership_id = this.verifyData.membership_type_id;
              this.verifyData.retype_email = this.verifyData.email;
              this.verifyApiData();
              return res;
            } else {
              return res;
            }
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {

      });
      this.wiz_step++;
    },
    recordPersonalData(personal) {
    
      this.age = this.getAge(personal.dob);
      // this.form.affiliate = personal.affiliate;

      if(personal.affiliate != undefined && this.subdomain=='waterski'){
          this.form.affiliate = personal.affiliate;
         // Vue.delete(personal, 'affiliate');
      }

      // Vue.delete(personal, 'affiliate');

      //shooting
      console.log("personal.club",personal.club);
      if(personal.club != undefined && personal.club != "" && this.subdomain=='shooting'){
          this.form.affiliate = personal.club == '-' ? personal.club : [personal.club];
           Vue.delete(personal, 'club');
      }
      
      if(personal.new_club_name != undefined){
          this.form.new_club_name = personal.new_club_name;
          // Vue.delete(personal, 'new_club_name');
      }
      if(personal.new_club_phone != undefined){
          this.form.new_club_phone = personal.new_club_phone;
          // Vue.delete(personal, 'new_club_phone');
      }
      if(personal.new_club_email != undefined){
          this.form.new_club_email = personal.new_club_email;
          // Vue.delete(personal, 'new_club_email');
      }
      if(personal.veteran_status != undefined){
          this.form.veteran_status = personal.veteran_status;
      }
      //shooting
      if(personal.diversity_txt != undefined){
        this.form.diversity_txt = personal.diversity_txt;
      }
      if(this.subdomain=='shooting'&&this.membership.member_type_id==7){
        this.form.first_choice = 21;
      }
      this.form.members[0] = personal;
      this.wizardNextstep();
      document.getElementById('app').scrollIntoView();
    },
    recordContactData(contact) {
      this.form.email = contact.email;
      this.form.retype_email = contact.retype_email;
      this.form.printed_magazine = contact.printed_magazine;
      this.form.address1 = contact.address1;
      this.form.address2 = contact.address2;
      this.form.zip = contact.zip;
      this.form.state_code = contact.state_code;
      this.form.state = contact.state;
      this.form.city = contact.city;
      this.form.country = contact.country;
      this.form.phone_1 = contact.phone_1;
      this.form.phone_2 = contact.phone_2;
      this.currentRegContacts = contact;
      this.wizardNextstep();
      //console.log(contact);
      //this.form.push(contact);
    },
    recordGeneralInfoData(general) {
      // this.form.safe_sport_status = general.safe_sport_status;
      // this.form.safe_sport_user_name = general.safe_sport_user_name;
      // this.form.safe_sport_email = general.safe_sport_email;
      // this.form.first_choice = general.first_choice;
      // this.form.second_choice = general.second_choice;
      // this.form.home_federation = general.home_federation;
      // this.form.skier_license = general.skier_license;

      console.log(">>>>> General:", general);

      let fields = Object.keys(general);
      fields.map((field, index) => {
          this.form[field] = general[field];
      });

      if(general.first_choice != undefined && Array.isArray(general.first_choice) && general.first_choice.length){
        let names = [];
        this.form.first_choice.forEach((id, index) => {
          if(this.disciplines[id] != undefined){
            names.push({ id: id, name: this.disciplines[id] });
          }
        });
        this.form.disciplines_names = names;
      }

      this.currentRegContacts.discipline_1 = general.first_choice;
      this.currentRegContacts.discipline_2 = general.second_choice;
      this.wizardNextstep();
    },
    recordDemographicData(demographic) {
      //this.form = demographic;
      //this.form.push(demographic);
      this.form.diversity_id = demographic.diversity_id;
      this.form.diversity_txt = demographic.diversity_txt;
      this.form.ethnicity = demographic.ethnicity;
      this.form.disability = demographic.disability;
      
      if(demographic.veteran_status != undefined && demographic.veteran_status != '')
        this.form.veteran_status = demographic.veteran_status;

      let personalData = {
          primary_area_interest: demographic.primary_area_interest,
          category: demographic.category,
          category_name: demographic.category_name,
          hear_about: demographic.hear_about,
          issf: demographic.issf,
          shirt_size: demographic.shirt_size,
          code_of_conduct: demographic.code_of_conduct,
          code_of_conduct_label: demographic.code_of_conduct_label,
          cmp_no: demographic.cmp_no,
          nra_no: demographic.nra_no,
          sasp_no: demographic.sasp_no,
          sctp_no: demographic.sctp_no
      };

      this.form.members[0] = {
          ... this.form.members[0],
          ... personalData
      }

      console.log('membership type id='+this.membership.id);
      this.wizardNextstep();
    },
    recordFamilyInfo({ familyMembers, removedFamilyMem })
    {
      // console.log(">>>>> Family Info: ", members);
      // this.form.members[family.index] = family;
      // console.log(">>Family: ",family.index, JSON.stringify(family));
      this.form.members = this.form.members.filter((item, index) => index == 0);
      // this.form.members = [ ... this.form.members, ... members ];
      this.form.familyMembers = familyMembers;
      this.form.removedFamilyMem = removedFamilyMem;

      console.log(removedFamilyMem);

      let minoruserinfo=[];
      familyMembers.forEach((value, index) => {
        let fmemberdob = this.getAge(value.dob);        
        if((fmemberdob < this.getSiteProps('memberRegistration.family.fields.family.popup.birthDay.child_age') && value.relation == '2') || (fmemberdob < 18 && value.relation == '3'))
        {
          minoruserinfo.push({
            "membership_type":this.waiverData[0].user_info[0].membership_type,
            "member_type_id":this.waiverData[0].user_info[0].member_type_id,
            "start_date":this.waiverData[0].user_info[0].start_date,
            "expires_at":this.waiverData[0].user_info[0].expires_at,
            "member_name": value.first_name+" "+value.last_name,
            "member_initials":value.first_name.charAt(0)+""+value.last_name.charAt(0),
            "signed_member":this.waiverData[0].user_info[0].signed_member,
            "spouse_alert":"0"
          });
         // this.currentWaiver++;
        } 
        else{
          this.waiverData[0].user_info[0].spouse_alert = 1;
        }       
      });
      let minorindx = null;
      this.waiverData.forEach((value, index) => {                       
        if (value.type == 'Minor') {
          minorindx = index;
        }        
      });
      if(minoruserinfo.length)
      { 
        this.waiverData[minorindx].user_info = minoruserinfo; 
      }
      else{
        this.waiverData.splice(minorindx,1);
      }
      console.log("current waiver", this.currentWaiver);
      // this.waiverData[2].user_info = minoruserinfo;   
       
      // this.addFilledWaiverData();
      // console.log("newf waiver",this.waiverData);
      // console.log("current waiver", this.currentWaiver);
      // console.log(this.waiverForm);
     
    },
    waiverFormSubmit() {
      if(this.validateWaiver()) {
        console.log('waiver valid');
        this.wiz_step++;
        document.getElementById('app').scrollIntoView();
        return;
      } else {
        console.log('waiver un valid');
      }
    },
    addFilledWaiverData()
    {
      let waiverNumber = this.currentWaiver > 0 ? this.currentWaiver-1 : 0;
      let currentWaiverLength = this.waiverData[waiverNumber].user_info.length;
      for(let i = 0; i < currentWaiverLength; i++) {
        this.waiverForm = {};
        this.waiverForm.waivers_id = this.waiverData[waiverNumber].waivers_id;
        this.waiverForm.initials = this.waiverData[waiverNumber].user_info[i].member_initials;
        this.waiverForm.signed_for = this.waiverData[waiverNumber].user_info[i].member_name;
        this.waiverForm.signed_user = this.waiverData[waiverNumber].user_info[i].signed_member;
        if (this.membership.member_type_id == 4) {
          this.waiverForm.club = this.clubSubmittedBy;
        }
        console.log(this.waiverForm);
        this.signedWaiverData.waivers.push(this.waiverForm);
      }
    },
    cartProducts(products) {
      this.cartProductData = products;
      console.log(products);
      console.log('--------------cart------------');
      this.calculateTotals();
    },
    applyCoupon() {
      let regData = {};
      // regData.price = parseFloat(this.totals.sub_total);
      regData.price = parseFloat(this.membership.fee);
      regData.coupon_code = this.couponApplied.coupon_code;
      regData.coupon_type_id = 2;
      this.button.coupon = 'Applying Coupon...';
      this.couponErrors = {};
      console.log('---------------');
      console.log(regData);
      axios.post(this.basePath+'api/check_coupon', regData)
          .then((res) => {
            if(res.data.status == 'success') {
              this.loadWaiver = false;
              this.couponApplied.status = 1;
              this.couponApplied.amount = res.data.data.value;
              this.couponApplied.text = res.data.data.text+' off membership ('+this.couponApplied.coupon_code+')';
              this.couponErrors.success = res.data.message;

              this.form.coupon_id = res.data.data.id;
              this.form.discount_amount = res.data.data.value;

              // if(this.membership.member_type_id == 4) {
              //   this.verifyClubApiData();
              // } else {
              //   this.verifyApiData();
              // }
              this.calculateTotals();
            } else {
              this.couponErrors.error = res.data.message;
              this.couponApplied.status = 0;
              this.couponApplied.amount = 0.00;
              this.couponApplied.text = '';

              this.form.coupon_id = null;
              this.form.discount_amount = 0.00;

              this.calculateTotals();
            }
            this.button.coupon = 'Apply Coupon';
            return res;
          })
          .catch((error) => {
            console.log('Error:'+ error);
            this.button.coupon = 'Apply Coupon';
            return false;
          }).finally(() => {
      });
    },
    removeCoupon(){
        this.couponApplied = {
            status: 0,
            coupon_code: '',
            amount: 0.00,
            text: ''
        };
        let data = {
          registration_id : this.regId
        }
        this.form.coupon_id = null;
        this.form.discount_amount = 0.00;
        this.couponErrors = {};
        this.calculateTotals();
    },
    removeCommaFromNumber(number) {
      return parseFloat(number.toString().replace(/,(?=.*\d+)/g, ''));
    },
    collectMemberFee(membership, fee, id, type, agree = null, age = null, member_type_id = "") {
      this.skipToVerify = false;
      console.log(fee + "==" + id + "===+" + type);
      this.form.member_type_text = type.replace("<br>", " ");
      this.form.member_type_fee = fee;
      this.form.membership_id = id;

      this.form.agree = (agree != null && agree != undefined) ? true : false;

      this.form.membership_agree = 0;
      this.membership = {
        id: id,
        type: type,
        fee: fee,
        age_criteria: age != null && age != undefined ? age : "",
        member_type_id: member_type_id,
        lifetime: membership.lifetime || false
      };

      // this.membership.age_criteria = age != null && age != undefined ? age : "";

      // this.membership.member_type_id = member_type_id;

      this.member_type_id = member_type_id;

      this.mountPropData();
    },
    mountPropData(){
      let prop_member_types = { 
        "1" : 'individual', 
        "2" : "support",
        "3" : "family",
        "4" : "club",
        "5" : "guests",
        "6" : "support",
        "7" : "fan"
      };
      let type = prop_member_types[this.member_type_id];

      if(type != undefined){
        const retry = setInterval(() => {
          this.steps = this.getSiteProps('memberRegistration.' + type);
          if(this.steps != null && this.steps.fields != undefined){
            let sections = Object.keys(this.steps.fields);
            if(sections && sections.length){
              sections.forEach(name => {
                  this.fields[name] = this.getSiteProps('memberRegistration.' + type + '.fields.' + name);
              });
              if(this.fields) clearInterval(retry);
            }
          }
        }, 200);
      }
    },
    stripeTokenCreated(res){
      this.stripeError = "";
      this.stripeToken = res.id;
      this.paymentForm.stripeToken = res.id;
      this.submitForm();
      // document.getElementById('app').scrollIntoView();
    },
    captureStripeErrors(errors){
        this.stripeError = errors;
        console.log("===========>",errors);
        this.showLoader=false;
    },
    stripeFormChanges(obj){
        this.stripeValidate = false;
        if(obj.complete){
            this.stripeError = "";
            this.stripeValidate = true;
        }
    },
    ageValidation(){
      if(this.propsMemberTypes.length){
        for(let memberType of this.propsMemberTypes){
          if(memberType.memberships != undefined && memberType.memberships.length){
            for(let membership of memberType.memberships){
              if(membership.age != undefined && membership.age.value != undefined && membership.age.compare != undefined){
                if(membership.age.compare == "below" && this.form.members[0].age > membership.age.value){
                  membership.disabled = 1;
                }
                if(membership.age.compare == "above" && this.form.members[0].age < membership.age.value){
                  membership.disabled = 1;
                }
              }
            }
          }
        }
      }
    },
    submitMerchandiseForm() {
      console.log("Waiver Form submitted!");
      //this.button.nextStepButton = 'Registration is in progress...';
      let renewMerchandise = {'orderId':this.placeOrderData.id,'regId':this.renewMerchandise.regId};
      console.log(renewMerchandise);
      axios.post(this.basePath + 'api/renewMerchandiseUpdate/' + this.regId, renewMerchandise)
          .then((res) => {
            if (res.data.status == 'success') {
              //Perform Success Action
              //alert('success')
              console.log('success',res);
              //console.log('success');
              //this.step++;
              //this.button.nextStepButton = 'Next Step';
              //this.regId = res.data.data.id;
              //this.paymentForm.registration_id = this.regId;
              //console.log(this.verifyData);
            }
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
        //this.verifyApiData();

      });
    },
    placeOrderCalculation() {
      let orderPlaceCalculation = {};
      console.log("Place order Calculation!");
      console.log('----------------------');
      //this.button.nextButton = 'Place order is in progress...';
      orderPlaceCalculation.registration_id = this.regId;
      orderPlaceCalculation.isRenew = true;
      axios.post(this.basePath+'api/get_merchandise_cart_products', orderPlaceCalculation)
          .then((res) => {
            console.log('----------'+res.data.status);
            if(res.data.status == 'success') {
              //Perform Success Action
              //Perform Success Action
              //alert('success')
              console.log('calculate success');

              //this.step++;
              this.button.nextButton = 'Next';
              this.placeOrderDataGets = res.data.data;
              this.cartProductCount = this.placeOrderDataGets.cart_product_count;
              this.totals.merchandise_total = this.placeOrderDataGets.sub_total;
              this.totals.shipping_total = this.placeOrderDataGets.shipping_cost;
              console.log(this.totals);
              this.calculateTotals();
              console.log('myloog');

              //console.log(this.verifyData);
              return res;
            } else if(res.data.status == 'error') {
              this.button.nextButton = 'Next';
              this.placeOrderDataGets = [];
              this.cartProductCount = 0;
              this.totals.merchandise_total = 0.00;
              this.totals.shipping_total = 0.00;
              this.totals.shipping_first_item = 0.00;
              this.totals.shipping_additional_item = 0.00;
              this.calculateTotals();
              return res;
            } else {
              this.button.nextButton = 'Next';
              this.placeOrderDataGets = [];
              this.cartProductCount = 0;
              this.totals.merchandise_total = 0.00;
              this.totals.shipping_total = 0.00;
              this.totals.shipping_first_item = 0.00;
              this.totals.shipping_additional_item = 0.00;
              this.calculateTotals();
              console.log('placeOrder success'+res.data.status);
              return res;
            }
          })
          .catch((error) => {
            console.log('Error:'+ error);
            //this.step--;
            this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
      });
    },
    getClubs(){
      axios.get(this.basePath + "api/get_teams")
          .then((response) => {
            var clubs = response.data.data;
            this.clubOptions = clubs;
          })
          .catch(error=>{
            console.log("error geting clubs list")
          })
    },
    removeStateCode(){
      this.paymentForm.billing_state = '';
      this.paymentForm.billing_state_code = '';
    },
  },
  beforeMount(){
    //Load properties
    const retry = setInterval(() => {
      let currentPath = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
      let url = this.basePath + "api/renewal-memberships";
      this.propsMemberTypes = this.getSiteProps("membershipTypes");
      if (currentPath == 'upgrade') {
        this.propsMemberTypes = [];
        url += "/"+currentPath;
      }
      axios.get(url, { headers: this.memberHeaders })
            .then(response => {
              let memberships = response.data.data;
              let membershipsArray = Object.keys(memberships);
              let prop_membertypes3 = Object.keys(this.propsMemberTypes);
                prop_membertypes3.forEach(type1 => {
                  let prop_keys = Object.keys(this.propsMemberTypes[type1].memberships);
                  prop_keys.forEach(type2 => {
                      membershipsArray.forEach(membershipId =>{
                        if(memberships[membershipId].id == this.propsMemberTypes[type1].memberships[type2].id){
                          if (this.currentPath != 'upgrade') {
                            this.propsMemberTypes[type1].memberships[type2].price = memberships[membershipId].price;
                          } else {
                            let currentMembershipPrice = memberships.find(membership => membership.id === this.membership.id).price;
                            this.propsMemberTypes[type1].memberships[type2].price = memberships[membershipId].price - currentMembershipPrice;
                          }
                          if(memberships[membershipId].price == 0){
                            this.propsMemberTypes[type1].memberships[type2].disabled = 1;
                          }
                        }
                      });
                  });
                });
            })
            .catch(error => {
                console.log("Error in getting categories: ", JSON.stringify(error));
            });
      if(this.propsMemberTypes) {
        this.priceFetching = false;
        clearInterval(retry);
      }
    }, 200);

    this.getDiscipline();
  },

  mounted() {
    const thePath = window.location.pathname;
    let lastItem = thePath.substring(thePath.lastIndexOf('/') + 1)  
    this.currentPath = lastItem;
    this.paymentForm.renew = 1;
    if(lastItem == 'upgrade'){
      this.paymentForm.upgrade = 1;
      this.paymentForm.renew = 0;
    }
    // this.getMemberType();
    this.getClubs();
    this.getActiveRegistration();

    this.merchandiseDataMenApi();
    this.merchandiseDataWomenApi();
    if(this.subdomain == 'waterski') this.merchandiseDataAccessoriesApi();
    this.getDiversity();
    this.getGroup();
    
    const year = new Date().getFullYear();
    this.yearArr = Array.from({length: 12}, (value, index) => year + index);
    this.getClubType();

    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.form.agree = false;
      this.form.membership_agree = 0;
      if(this.propSelectedMembership && this.propSelectedMembership.id !== undefined && this.propSelectedMembership.id === this.membership.id && this.propSelectedMembership.agree !== undefined){
        this.form.agree = true;
      }
    });

    const retry = setInterval(() => {
      if(this.fields.demographic && this.fields.demographic.category != undefined){
          this.getCategories();
          clearInterval(retry);
      }
    }, 200);

    const retry2 = setInterval(() => {
      //Init processing fee percent from property file
      let registration_fee_percent = this.getSiteProps("general.registration_fee_percent");
      if(registration_fee_percent && this.processingFeePercent == 0 && registration_fee_percent != undefined && !isNaN(registration_fee_percent)){
          this.processingFeePercent = registration_fee_percent;
          clearInterval(retry2);
      }
    }, 200);

    const retry3 = setInterval(() => {
      this.prop_confirmation = this.getSiteProps('memberRegistration.confirmation');
      if(this.prop_confirmation) clearInterval(retry3);
    }, 200);
  },
  computed: {
    verifyEditData() {
      return this.verifyData;
    },
    openCollapse(){
      var _index = -1;
      this.propsMemberTypes.forEach((item, index) => {
        if(item.visible != 0 && _index == -1) _index = item.id;
      });
      return _index;
    },
    shippingCosts: function () {
      return this.getSiteProps('merchandise_shipping');
    },
    shippingTotal: function(){
      let shippingCost = 0.00;
      if(this.cartLength >= 6) {
        shippingCost =  parseFloat(this.shippingCosts.shipping_above_six_item).toFixed(2);
      } else if(this.cartLength <= 5 && this.cartLength >= 3) {
        shippingCost =  parseFloat(this.shippingCosts.shipping_four_to_six_item).toFixed(2);
      } else if(this.cartLength <= 2 ) {
        shippingCost =  parseFloat(this.shippingCosts.shipping_first_three_item).toFixed(2);
      }
      return shippingCost;
    },
    propMembershipTypes: function(){
      return this.getSiteProps("membershipTypes");
    }
  },
  filters: {
    formatDob(value){
      if (value) {
          return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    filterBr(value){
        return value.replace("<br>", " ");
    },
    fullNumber(value){
        return value.toFixed(2);
    },
    appendMembershipWord(text){
      return text != undefined ? text.toString().toLowerCase().replace(" membership", "") : "";
    }
  }
}
</script>

<style scoped>

</style>
